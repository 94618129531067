

.button-link{
  a{
    text-decoration: none;
    flex:6;
    width:fit-content;
  }
  .content{
    padding:20px 30px;
    border-radius:30px;
    color:white;
    text-align: center;
    font-weight:bold;
    width:fit-content;
  }

  .content:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}


@media only screen and (max-width:640px){
  .button-link{
    .content{
      margin:0 auto;
    }
  }
}
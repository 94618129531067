.cookies-consent{
  position:absolute;
  width:100%;
  height:100vh;

  .consent-form{
    position:fixed;
    width:100%;
    background-color:#f5f5f5e1;
    border-top:1px solid grey;
    padding-top:25px;
    padding-bottom:25px;
    padding-left:10vw;
    padding-right:10vw;
    bottom:0;
    z-index:110;
    display:flex;
    justify-content: center;
    align-items: center;
    .text{
      opacity: 1;
      padding-left:5vw;
      padding-right:5vw;
      flex:6;
      text-align: left;
    }
    .button-container{
      padding-left:5vw;
      padding-right:5vw;
      flex:6;

      .button{
        color:white;
        padding:12px;
        border-radius:15px;
        background-image: linear-gradient(270deg, #ff00c4, #7f21cd);
        width:fit-content;
        cursor:pointer;
      }
    }

  }
}

@media only screen and (max-width:1492px){
  .cookies-consent{
    .consent-form{
      padding-left:5vw;
      padding-right:5vw;
    }
  }
}

@media only screen and (max-width:731px){
  .cookies-consent{
    .consent-form{
      flex-direction:column;
      .text{
        margin-bottom:15px;
      }
    }
  }
}


.filter-component{
  height:fit-content;
  text-align: left;
  color:#323366;
  display:flex;
  justify-content: center;
  max-width:1600px;
  margin:10px auto;

  .filter-underlay{
    position: fixed;
    width:100vw;
    height:100vh;
    top:0;
    z-index: 1;
  }

  .filter-button{
    display:none;
    width:40px;
    height:30px;
    object-fit: fill;
  }

  .filters-active{
    z-index:2;
  }

  .filters{
    width:100%;
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    margin:0 17px;

    .filter-modal-button{
      height:fit-content;
      background-color:white;
      border: 2px solid rgb(153 204 204);
      left:0px;
      bottom:-60px;
      color:#686D7A;
      padding:17px 23px;
      border-radius:30px;
      font-weight:bold;
      letter-spacing: 1px;
      cursor:pointer;
      font-size:16px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      white-space: nowrap;
      width:fit-content;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
    }

    .mobile-filters-container{
      background-color:white;
      position:fixed;
      top:100px;
      z-index:100;
      width:100vw;
      max-width:100vw;
      align-items:center;
      border-radius:15px 15px 0 0;
      max-height:calc(100vh - 100px);
      overflow-y:scroll;
      left:0;

      .header{
        display:flex;
        justify-content: center;
        align-items: center;
        width:100vw;
        .title{
          font-size:16.8px;
          padding:15px;
          flex:11;
        }

        .close-icon{
          cursor:pointer;
          flex:1;
          img{
            width:35px;
          }
        }
      }
      .mobile-filter{
        padding:15px;
        .heading{
          padding:15px 0;
          font-size:16.8px;
          text-align: left;
          font-weight:bold;
        }
        .divider{
          border-bottom: 1px solid #336699;
          width:99%;
          margin:0 auto;
          opacity: 0.2;
          padding-top:15px;
        }
      }
      .filter-footer{
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding:10px 0 25px 0;
        .reset{
          flex:6;
        }

        .close{
          flex: 6;
        }
      }

    }

    .filter{
      margin: 10px 15px;
      position:relative;
      .filter-heading{
        background-color:white;
        border: 2px solid rgb(153 204 204);
        left:0px;
        bottom:-60px;
        color:#282F41;
        padding:17px 23px;
        border-radius:30px;
        font-weight:bold;
        letter-spacing: 1px;
        cursor:pointer;
        font-size:16px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        white-space: nowrap;
        width:fit-content;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */

        img{
          padding-left:10px;
        }

      }
    
      .filter-content{
        top:72px;
        background-color: white;
        padding:30px;
        width:400px;
        border-radius:10px;
        border: 1px solid #CDCDCD;
        position: absolute;
        z-index:100;
      }
    }
  }

  

  

  .age-range{
    .range-container{
      margin: 0 auto;
    }
    .range-text{
      margin-top:3px;
    }
  }

  .search-container{
    margin:0 auto;
    min-width:400px;

    .search {

      .search-icon{
        position:absolute;
        right:20px;
        top:20px;
        cursor:pointer;
      }
      width: 100%;
      position: relative;
      display: flex;
    }

    .search-small{
      display:none;
    }

    

    .searchTerm {
      width: 100%;
      border: 2px solid #99cccc;
      padding:17px 18px;
      height: 20px;
      border-radius: 35px;
      outline: none;
      font-size:18px;
      color:#282F41;
    }



    .searchTerm:focus{
      color:#282F41;
    }

    .searchButton {
      width: 40px;
      height: 36px;
      border: 1px solid #8c1ecc;
      background: #8c1ecc;
      color: #fff;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      font-size: 20px;
    }

    .search-active{

      .searchTerm{
        border: 2px solid #4592c2!important;
      }

      .search-icon{
        filter: invert(82%) sepia(76%) saturate(916%) hue-rotate(328deg) brightness(102%) contrast(100%);
      }
    }
  }

  .title{
    display:flex;
    color:#323366;
    font-size:26px;
    text-align:left;
    font-weight: bold;
  }

  .description{
  }
}

@media only screen and (max-width:1650px){
  .filter-component{
    .filters{
      justify-content: space-evenly;
    }
  }
  .filter{
    padding:20px;
  }
  .age-search{
  }
}

@media only screen and (max-width:1000px){
  .filter{
    z-index: 102;
    max-width:none;
  }
}

@media only screen and (max-width:768px){
  .filter-component{
    padding:10px 10px 0 10px;
    .filters{
      flex-wrap:nowrap;
      justify-content: space-between;
      margin:0;
      .age-search{
        .search-container{
          min-width:300px;
        }
      }
    }

  }
}


@media only screen and (max-width:520px){
  .filter-component{
    padding:10px 5px 0 5px;
    .filters{
      flex-wrap:nowrap;
      .filter-modal-component{
        width:auto;
      }

      .age-search{
        width:auto;
        .search{
          .search-container{
            width:auto;
            min-width:100px;

          }
        }
      }

      .search-big{
        display:none;
      }

      .search-small{
        display:inherit;
      }
    }

  }
}


@media only screen and (max-width:440px){
  .filter-component{
    .filters{
      .age-search{
        .search{
          .search-container{
            .search-icon{
            }
          }
        }
      }
    }
  }
}
.premade-pack-min{
  background-color:#f5f5f5;
  min-height:calc(100vh - 80px);
  display:flex;
  margin: 0 auto;
  flex-direction: column;


  .no-packs{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    p{
      text-align: center;
      padding-bottom:15px;
    }
    .reset-button{
      padding: 20px 30px;
      border-radius: 30px;
      color: white;
      text-align: center;
      font-weight: bold;
      width: fit-content;
      border: 2px solid #339966;
      color: #339966;
      text-decoration: none;
      cursor:pointer;
    }
  }

  .heading{
    text-align: center;
    padding:30px;
    h2{
      margin-top:0px;
      margin-bottom:0px;
    }
    p{
      text-align: center;
      max-width:620px;
      margin:0 auto;
    }
  }

  .item-count{
    text-align: center;
  }

  .error{
    width:100%;
    font-size:26px;
    color:#323366;
  }
  .go-to-cart{
    position:fixed;
    z-index:100;
    bottom:0px;
    right:0px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:white;
    padding:5px;
    font-weight:bold;
    background-color:#323366;
    border-radius:15px 0 0 0;
    .text{
      font-size:20px;
    }
    .icon{
      width:40px;
      height:40px;
      color:white;
      fill:#fff;
    }
  }

  .premade-packs{
    display:flex;
    margin: 0 auto;

    .selection{
      .text-box{
        .heading{

        }

        .description{
          
        }
      }
    }

    .filter{
      flex:0 0 20%;
      background-color: white;
      box-shadow: 10px 10px 15px -3px rgba(0,0,0,.1), 10px 4px 6px -2px rgba(0,0,0,.05);
      width:100%;
      height:fit-content;
      .filter-button{
        display:none;
        width:40px;
        height:30px;
        object-fit: fill;
      }

      h4{
        text-align: left;
        margin-left:11%;
        margin-top:10px;
        margin-bottom:10px;
      }

      .search-container{
        width: 80%;
        margin:0 auto;

        .search {
          width: 100%;
          position: relative;
          display: flex;
        }

        .searchTerm {
          width: 100%;
          border: 1px solid #8c1ecc;
          padding: 5px;
          height: 20px;
          border-radius: 5px 5px 5px 5px;
          outline: none;
          color: black;
        }

        .searchTerm:focus{
          color: black;
        }

        .searchButton {
          width: 40px;
          height: 36px;
          border: 1px solid #8c1ecc;
          background: #8c1ecc;
          text-align: center;
          color: #fff;
          border-radius: 0 5px 5px 0;
          cursor: pointer;
          font-size: 20px;
        }
      }

      .title{
        display:flex;
        justify-content: center;
        align-items: center;
        font-size:26px;
        text-align:center;
        margin-top:20px;
        font-weight: bold;
      }

      .filter-list{
        list-style:none;

        li{
          display:flex;
          justify-content: center;
          align-items: center;

          label{
            flex:4;
            text-align:left;
          }

          input{
            flex:4;
          }
        }
      }

      .range-container{
        margin: 0 auto;
        width:75%;
      }
    }

    .item-carousels{
      display:inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      max-width: 1600px;

      @media(max-width:1140px){
        margin-left:0;
      }

      .create-new-pack, .random-pack{
        min-width:97%;
        margin:0 auto;
        margin-bottom:15px;
        padding:10px;
        background-color:white;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height:50px;
        position:relative;
        .image{
          position:absolute;
          opacity:0.7;
          flex-shrink: 0;
          width: 100%;
        }

        .content{
          display:flex;
          flex-wrap:wrap;
          z-index:10;
          justify-content:center;
          align-items: center;
          width:100%;
          flex-direction: row;
          margin:0 auto;
          
          .text{
            flex:7;
            font-size:17px;
            font-weight:bold;
            text-align: left;
            color:#212121;
            width:100%;
            padding-left:15px;
            text-align:center;
          }
          .button-container{
            flex:5;
            .button{
              background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
              padding:11px;
              border:none;
              min-width:100px;
              border-radius:5px;
              font-size:14px;
              color:white;
              font-weight:bold;
            }
          }
        }
      }

    }

    @media(max-width:1140px){
      flex-direction:column;

      .filter{
        margin-bottom:10px;

        h4{
          text-align: left;
          margin-top:10px;
          margin-bottom:10px;
        }

        .search-text{
        }

        .search-container{
        }

        .clickables{
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .preferred-gender{
            flex:6;
          }

          .other{
            flex:6;
          }
        }
        .range-container{
          margin: 0 auto;
          width:75%;
        }

      }

      .item-carousels{
      }
    }
  }
  .background-image{
    position: absolute;
    width:100%;
    background-size:cover;
    object-fit:cover;
    left:0;
    z-index:-2;
    opacity:.5;
  }

  .card-container{
    display: inline-flex;
    flex-wrap:wrap;
    margin:50px;
    justify-content: center;
    align-items: center;

    .card{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color:white;
      box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
      padding:10px;
      margin: 10px auto;
      margin:10px;

      .item-description {
        padding:10px;
      }

      .image-container{
        display:grid;
        grid-template-columns: 130px 130px 130px;
        grid-gap:10px;
        padding:10px;

        
      }

      .add-button {
          background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
          color:white;
          padding:15px;
          border:none;
          border-radius:5px;
          margin:10px;
          cursor:pointer;
          font-size:16px;
        }
    }
  }

  .content{
    display:flex;
    flex-direction: column;
    .heading{
      margin-left:12px;
      text-align: center;
      margin-bottom:20px;
      color:#323366;
      .title{
        font-size:25px;
      }
    }
    .header{
      text-align: left;
      margin-bottom:50px;

      .title{
        margin-top:20px;
        font-size:45px;
        color:#212121;
      }

      .subtitle{
        font-size:25px;
        color:#212121;
      }
    }

    .slider-value{
      font-size:25px;
      margin-bottom:7px;
    }

    .slider-container{
      margin: 0 auto;
      width:60%;
      margin-bottom:50px;
    }

    .transition-container{
      height:100vw;
      overflow:hidden;
      height:100%;
      display:flex;
    }
  }
}


/* FADE */
.fade-appear {
    opacity:0;
}
.fade-appear-active {
  opacity:1;
  transition: opacity 500ms;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity:1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity:1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* NODE */
.my-node-appear {
  opacity: 0;
}
.my-node-enter {
  opacity: 0;
  position:absolute;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.my-node-exit {
  opacity: 1;
  position:absolute;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* ANIMATIONS */
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.slide-exit {
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}


/* SLIDE PACK */
.slide-pack-enter {
  transform: translateX(-100%);
}
.slide-pack-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.slide-pack-exit {
  transform: translateX(0%);
}
.slide-pack-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

/* SLIDE CATALOGUE */
.slide-cat-enter {
  transform: translateX(100%);
}
.slide-cat-enter-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}
.slide-cat-exit {
  transform: translateX(-100%);
}
.slide-cat-exit-active {
  transform: translateX(100%);
  transition: transform 500ms ease-in-out;
}

@media only screen and (max-width: 884px) {
  .premade-pack-min{
    .content{
      .premade-packs{
        .item-carousels{
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .custom-pack .choose-pack{
    padding:0;
  }

  .premade-pack-min{
    .heading{
      padding-bottom:5px;
    }
  }
}

@media only screen and (max-width:1650px) {
  .premade-pack-min{
    max-width:1350px;
  }
}

@media only screen and (max-width:1450px) {
  .premade-pack-min{
    max-width:1000px;
  }
}

@media only screen and (max-width: 530px){
  .premade-pack-min{
    .content{
      .premade-packs{
        .item-carousels{
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .premade-pack {
    .footer-item-info{
      padding:0;
      .divider{
        width:80%;
      }

      .button-container{
        div{
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .premade-pack-min{
    .heading{
      padding:15px 15px 5px 15px;
    }
  }
}





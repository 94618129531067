.bread-crumbs{
  display:flex;
  padding-top:50px;

  .crumb{
    display:flex;

    .crumb-title{
      padding:10px 10px 10px 0px;
      a{
        text-decoration: none;
        color:#282F41;
        opacity: 0.4;
      }
    }

    .crumb-divider{
      padding:10px;
    }

    .unauthorised{
      pointer-events: none;
    }
  }

  .active{
    font-weight:bold;
    .crumb-title{
      a{
        opacity:1;
      }
    }
  }
}

@media only screen and (max-width:1000px){
  .bread-crumbs{
    padding-top:0px;
  }
}

@media only screen and (max-width:468px){
  .bread-crumbs{
    display:flex;
    flex-wrap:wrap;
      .crumb{
        .crumb-title{
          padding-left:0px;
          padding-right:0px;
        }
      }
  }
}

@media only screen and (max-width:385px){
  .bread-crumbs{
      .crumb{
        .crumb-title{
          a{
            font-size:14px;
          }
        }
        .crumb-divider{
          padding-left:5px;
          padding-right:5px;
        }
      }
  }
}

.orders-table{
  display:flex;
  flex-direction:column;

  .heading-container{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px #EBEBF2 solid;
    margin-bottom:8px;
    .title{
      flex:6;
      text-align: left;
      color:#282F41;
      margin: 10px 0;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }

    .edit-link-container{
      flex:6;
      max-width:300px;
      text-align: right;

      .edit-link{
        a{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color:#686D7A;
          text-decoration: underline;
        }
      }
      
    }
  }



  .field-section{
    display:flex;
    flex-wrap:wrap;
    text-align: left;
    border-radius:10px;
    margin-bottom:15px;

    .section-heading{

    }
    .form-control{
      width:calc(50% - 40px);
      display:flex;
      padding:10px;
      
      .label{
        font-weight: bold;
        min-width:155px;
        line-height: 28px;
      }

      .value{
        line-height:28px;
      }

      .input-component{
        width:100%;
      }
    }
  }

  .orders{
    .order-table{
      display:table;
      width:100%;
      * {
        display:table-row;
      }

      .mobile-label{
        display:none;
      }

      .cell{
        display:table-cell;
        align-items: center;
        width:200px;
        vertical-align: middle;
        font-size:20px;

        .content{
          vertical-align: middle;
        }
        .order-links{
          display :flex;
          width:100%;
          justify-content: space-between;
          .order-link{
            padding: 0 5px;
            color:#339966;
            text-decoration: underline;
            font-size:18px;
          }
        }
      }

      .headings{
        .cell{
          font-size:16px;
          color:#686D7A;
          font-weight:700;
          padding-bottom:10px;
        }
      }

      .order-no-cell{
        width:60px;
      }

      .total-cell{
        width:50px;
      }

      .status-cell{
        width:100px;
      }

      .date-cell{
        width:100px;
      }

      .options-cell{
        .content{
          display:flex;
        }
      }
    }
  }
}


@media only screen and (max-width:1354px){
  .orders-table{
    .orders{
      .order-table{
        .headings{
        }
        .mobile-label{
        }
        .cell{
          font-size:16px;
        }
        .row{
          .cell:last-child{
          }
        }
      }
    }
  }
}

@media only screen and (max-width:700px){
  .orders-table{
    .orders{
      .order-table{
        .headings{
          display:none;
        }
        .mobile-label{
          display:block;
        }
        .cell{
          display:flex;
          justify-content: space-between;
          width:auto;
        }
        .row{
          .cell:first-child{
            padding-top:5px;
          }
          .cell:last-child{
            padding-bottom:5px;
            border-bottom: 1px #EBEBF2 solid;
          }
        }

        .options-cell{
          .content{
            .order-links{
              .order-link:last-child{
                padding-right:0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:400px){
  .orders-table{
    .orders{
      .order-table{
        .row{
          .options-cell{
            .content{
              width:100%;
            }
            .order-links{
              flex-wrap:wrap;
              .order-link{
                padding:0;
              }
            }
          }
        }
      }
    }
  }
}
.maintenance-mode{
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .logo{
    width:600px;
    padding-top:100px;
  }
  .heading{
    text-align: center;
  }

  .sub-heading{

  }
}

@media only screen and (max-width:700px){
  .maintenance-mode{
    .logo{
      max-width: calc(100vw - 60px);
    }
  }
}
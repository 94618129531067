.password-reset-info{
  .container{
    min-height:calc(100vh - 80px);
    margin-left:10vw;
    margin-right:10vw;
    padding:20px;
    margin-bottom:20px;
    .title-block{

      .title{
        font-size:40px;
      }

      .close-button-container{
        width:100%;
        margin-top:10px;

        .close-button{
          float:right;
          background-color:#ff00c4;
          padding:1rem 1.5rem 1rem 1.5rem;
          border:none;
          opacity:.7;
          border-radius:.25rem;
          color:white;
        }
      }
    }
  }

  .password-reset-form{
    padding:10px;
    display:flex;
    flex-direction: column;

    .form-input{
      display:inline-flex;
      flex-direction:row;
      flex-wrap:wrap;

      .form-section{
        flex:4;
        margin-left:5px;
        margin-right:5px;
        min-width:330px;
      }

      input{
        display:flex;
        border-radius:.25rem;
        border:0 solid #dedede;
        border-width: 1px;
        padding:.75rem;
        padding-right:0px;
        width:calc(100% - 0.95rem);
      }

      .select{
        display:flex;
        border-radius:.25rem;
        border:0 solid #dedede;
        border-width: 1px;
        padding:.75rem;
        width:100%;
        background-color:white;
      }

      .form-control{
        margin:0px 10px 0px 10px;
        padding:5px;
        flex:6;

        label{
          display:block;
          text-align:left;
          padding:5px;
          font-size:14px;
          color:#4a5568;
        }


      }
    }

    .address-heading{
      text-align:left;
      padding:0rem 1rem 0rem 1rem;
    }

    .form-submit {
      input{
        float:right;
        background-color:#ff00c4;
        padding:1rem 1.5rem 1rem 1.5rem;
        border:none;
        opacity:.7;
        border-radius:.25rem;
        color:white;
        margin-top:10px;
      }
    }

    .loader{
      width:80px;
      margin:0 auto;
    }
  }

}

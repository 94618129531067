.about{
    max-width:1920px;
    color:#323366;
    margin:0 auto;
    background-color:white;
    .heading-container{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:80px;
        background-color:#f5f5f5;
        padding-bottom:50px;
        color:#282f41;

        .heading{
            font-style: normal;
            font-weight: 700;
            font-size: 61.04px;
            line-height: 110%;
            margin-bottom:30px;
            text-align: center;
        }

        .sub-heading{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            max-width:600px;
        }
    }



    .container{
        background-color:white;
        display:flex;
        flex-direction: column;
        align-items: center;
        max-width:1600px;
        margin: 0 auto;
        margin-top:100px;
        margin-bottom: 100px;

        .row{
            display:flex;
            justify-content: center;
            align-items: center;
            padding: 0 2vw;

            .text-container{
                flex:4;

                .heading{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 48.83px;
                    line-height: 120%;
                }

                .text{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 140%;
                }
            }

            .image-container{
                flex:3;
                display:flex;
                margin:0 auto;

                img{
                    border-radius:25px;
                    margin:0 auto;
                    width:400px;
                }
            }
        }

        .row:first-child{
            .image-container{
                justify-content: flex-end;
            }
        }
    }
}

@media only screen and (max-width: 1000px){
    .about{
        .container{
            .row{
                flex-direction: column;
                padding:0 10vw;

                .text-container{
                    max-width:600px;
                    margin-top:80px;
                }

                .image-container{
                    margin-top:20px;
                    img{
                        width:100%;
                    }
                }
            }

            .reverse{
                flex-direction: column-reverse;
            }
        }
    }
}



.cart-summary {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  .underlay{
    position:fixed;
    top:0;
    width:100vw;
    height:100vh;
    z-index:1001;
    background-color:rgba(0,0,0,0.1)
  }

  .party-pack:last-child{
    .pack-price-container{
      border:none;
    }
  }


  .content{
    display:block;
    position:absolute;
    right:0;
    width:450px;
    height:100vh;
    background-color:#F6F6F8;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    z-index:1002;
    padding:0 25px 0 25px;
    -webkit-transform-style: preserve-3d;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    
    .heading {
      padding-bottom:15px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EBEBF2;
  
      .title{
        text-align: left;
      }
  
      .cross-icon{
        cursor: pointer;
  
        img{
          height:35px;
          width:35px;
        }
      }
    }
  
    .container{
      height:calc(100vh - 276px);
      margin-left:0;
      margin-right:0;
  
      .cart-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:initial;

        .fixed-container{
          position:absolute;
          bottom:0;
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 25px);
          padding-top:10px;
          border-top:1px solid #99CCCC;


          .total-price-container{
            display:flex;
            justify-content: center;
            align-items: center;
            width:100%;
        
            .label{
              flex:6;
              text-align: left;
              font-weight: bold;
              font-size:18px;
            }
            .total-price{
              color:#282F41;
              flex:6;
              display:flex;
              align-items: center;
              justify-content: flex-end;
              float:right;
              font-size:18px;
              position: relative;
              font-weight:bold;
            }
          }
  
          .total-price-container:nth-child(4){
            border-bottom:1px solid #99CCCC;
            padding-bottom:10px;
            margin-bottom:25px;
          }
          .cart-button-container{
            padding-top:10px;

            padding-bottom:10px;
            display:flex;
            width:100%;
            margin:0 auto;
            .cart-button{
              padding: 15px 80px;
              border-radius: 30px;
              color: white;
              text-align: center;
              font-weight: bold;
              width: 100%;
              border: 2px solid #339966;
              color: #339966;
              text-decoration: none;
              margin-bottom:25px;
              cursor:pointer;
              margin:0 auto;
            }
          }
        }




  
        .cart-items{
          display:flex;
          flex-direction: column;
          width:100%;

          .cart-card{
              max-height:calc(100vh - 335px);
              overflow-y:scroll;
  
            .header-container{
              .header{
                text-align: left;
                margin:0;
              }
            }
  
            .party-details {
              text-align: left;
              margin:4px;
              .party-detail {
                flex:3;
  
  
              }
            }
  
            .party-packs-text{
              text-align: left;
              margin:4px;
            }
  
  
            .delete-party-button{
              float:right;
              padding:15px;
              border:none;
              border-radius:5px;
              margin:20px;
              font-size:14px;
              color:white;
              cursor:pointer;
            }
  
            .party-supplies-banner{
              background-color:black;
              box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
              margin-bottom:20px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              height:100px;
              position:relative;
  
              .image{
                position:absolute;
                opacity:0.7;
                flex-shrink: 0;
                width: 100%;
              }
  
              .content{
                display:flex;
                justify-content:center;
                align-items: center;
                width:100%;
                .text{
                  flex:9;
                  font-size:25px;
                  font-weight:bold;
                  text-align: left;
                  color:white;
                  width:100%;
                  padding-left:15px;
                }
                .button-container{
                  .button{
                    background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
                    padding:15px;
                    border:none;
                    min-width:100px;
                    border-radius:5px;
                    font-size:16px;
                    color:white;
                    font-weight:bold;
                  }
                }
              }
  
            }
          }
        }

        .cart-summary-container{
          width:50vw;
  
          .card{
            background-color:white;
            box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
            padding:20px;
            .header{
              font-size:32px;
              text-align:left;
            }
  
            .content{
              display:flex;
              flex-direction: column;
              align-items: left;
              justify-content: left;
              text-align: left;
              padding:15px;
              .text{
                padding:3px;
                .label{
  
                }
                .value{
                  float:right;
                }
              }
          }
  
          }
          .checkout-form{
            display:flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-wrap: wrap;
            padding-top:20px;
            @media(max-width:1360px) {
              .date-field{
                margin-top:10px;
  
                flex-direction: column;
  
                .label{
                  padding-bottom:10px;
                }
              }
            }
  
            .date-field{
              flex:9;
              display:flex;
              width:80%;
              flex-direction: column;;
              .label{
                padding-left:2px;
                padding-bottom:5px;
                font-size:24px;
                color:#323366;
                flex:4;
                text-align:left;
                font-weight:bold;
              }
  
              input{
                display:flex;
                flex:3;
                border-radius:10px;
                border:5px solid #323366;
                border-width: 1px;
                padding:14px;
                font-size:17px;
                font-weight:bold;
                cursor:pointer;
              }
  
              input::placeholder{
                color:#98159c;
              }
  
              .date-picker{
                position:relative;
                overflow:hidden;
              }
              .date-picker::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
              }
            }
            .submit-button-container{
              min-width: 200px;
              flex:6;
              align-items: baseline;
              .submit-button{
                background-color:#323366;
                padding:15px;
                border:none;
                border-radius:25px;
                font-size:20px;
                font-weight:bold;
                color:white;
                float:right;
                cursor:pointer;
                @media(max-width: 1268px){
                  padding:12px;
                }
              }
            }
  
          }
  
          .add-party-pack-button{
            background-color:#323366;
            float:left;
            padding:15px;
            border:none;
            border-radius:25px;
            margin:20px 0;
            font-size:20px;
            font-weight:bold;
            color:white;
            text-decoration: None;
            cursor:pointer;
          }
        }
      }
  
      .empty-cart{
        height:calc(100vh - 300px);
        margin:25px;
        .text{
          text-align: center;
        }
        .button-container{
          padding-top:20px;
          display:flex;
          justify-content: center;
          align-items: center;
    
          a{
            text-decoration: none;
            .landing-button{
              flex:6;
              padding:20px 30px;
              border-radius:30px;
              color:white;
              text-align: center;
              font-weight:bold;
              width:fit-content;
            }
          }
    
          .build{
            background-color: rgb(153 204 204);
            margin-right:5px;
          }
    
          .pick{
            background-color: rgb(224 132 163);
            margin-left:5px;
          }
        }
      }
    }
  }

  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    animation-delay: 0s;
    animation-timing-function: ease-out;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }

  .slide-out {
      animation: slide-out 0.5s forwards;
      -webkit-animation: slide-out 0.5s forwards;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
  }

  .closed{
    animation: slide-out 0s forwards;
    -webkit-animation: slide-out 0s forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
      
  @keyframes slide-in {
      0% { transform: translateX(100%); }
      100% { transform: translateX(0%); }
  }

  @-webkit-keyframes slide-in {
      0% { -webkit-transform: translateX(100%); }
      100% { -webkit-transform: translateX(0%); }
  }
      
  @keyframes slide-out {
      0% { transform: translateX(0%); }
      100% { transform: translateX(100%); }
  }

  @-webkit-keyframes slide-out {
      0% { -webkit-transform: translateX(0%); }
      100% { -webkit-transform: translateX(100%); }
  }


}

.transition-container{
  overflow:hidden;
  height:100%;
  display:flex;
  background-color:#f5f5f5;
}

@media only screen and (max-width: 800px) {
  .cart{
    .container{
      .cart-container{
        flex-direction:column;
        .cart-items{
          width:auto;
          .card-container{
            justify-content: center;
            .card{
            }
          }

        }
        .cart-summary-container{
          width:auto;
        }
      }
    }

  }
}

@media only screen and (max-width: 1010px) {
  .cart{
    .container{
      .cart-container{
        .cart-summary-container{
          .checkout-form{
            justify-content: center;
            align-items: center;
            .date-field{
              .label{
                text-align: center;
              }
              .datepicker{
                margin:0 auto;
              }
            }

            .submit-button-container{
              margin:10px;
              .submit-button{
                float:none;
                padding:15px 25px;
              }
            }
          }

          .add-party-pack-button{
            float:none;
            margin:10px auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:840px){

  .cart-summary{
    .underlay{
      display:none;
    }
  
    .content{
      z-index:999999;
      height:100vh;
      top:0;
      width:100%;
      padding:0;

      .heading{
        padding:30px;
        .title{
          margin:0;
        }
      }

      .container{
        .cart-container{
          padding:0px 30px 0px 30px;
          .cart-items{
            .cart-card::-webkit-scrollbar{
              display:none;
            }
          }
          .fixed-container{
            background-color:#F6F6F8;
          }
        }
      }
    }
  }

}

.account{
  background-color:#f5f5f5;
  background-size:cover;
  min-height:calc(100vh - 80px);
  padding:25px 50px;
  max-width:1600px;
  margin:0 auto;

  .heading{
    width:100%;
    text-align: left;
    color:#323366;
    .title{
      font-weight:bold;
      font-size:45px;
    }
  }

  .container{
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin:0 auto;


    .nav-container{
      display:flex;
      flex-direction: column;
      .title{
        text-align: left;
        color:#323366;
        font-weight: bold;
        font-size:22px;
        margin: 10px 0;
      }
      .navbar{
        flex:2;
        margin-top:50px;
        height: 400px;
        align-items: center;
        justify-content: center;
        border-radius:10px;
  
        .list-heading{
          text-align: center;
          font-size:35px;
          font-weight:bold;
          color:#fff;
          padding:10px;
          background-color:#323366;
          border-radius: 10px 10px 0 0;
        }
  
        .navlist{
          text-align: left;
          display:flex;
          flex-direction:column;
          align-items: center;
  
          .navlink{
            margin:5px 0;
            text-decoration: none;
            box-sizing:border-box;
            line-height: 40px;
            height:50px;
            width:100%;
            padding:5px 15px;
            border-radius:5px;
            cursor:pointer;
            font-size:18px;
            min-width:220px;
            display:flex;

            .image-container{
              display:flex;
              justify-content: center;
              align-items: center;
              width:40px;
              padding-right:10px;
            }
          }
  
          .active{
            background-color:rgba(154, 154, 182, 0.2);
          }
        }
      }
    }


    .account-container{
      margin:0 15px 15px 15px;
      flex:10;
      padding:0 20px 20px 20px;
      margin-bottom:20px;
      .no-orders{
        text-align: left;
        margin-bottom:20px;
      }

      .heading{
        text-align: left;
        margin-left:25px;
      }

      .address-heading{
        text-align:left;
        padding:0rem 0rem 0rem 0rem;
        margin-top:5px;
        margin-bottom:15px;
      }
    }
  }
}


@media only screen and (max-width:1000px){
  .account{
    .container{
      flex-direction:column;

      .navbar{
        margin-bottom:20px;
        .navlist{
          flex-direction: row;
        }
      }

      .account-container{
        padding:0;
        margin:0;
      }
    }
  }
}

@media only screen and (max-width:1000px){
  .account{
    padding:15px;
  }
}

.yoco{
  padding-top:0;

  .cust-info, .payment-info{
    margin-bottom: 20px;
    padding:25px;
    background-color:white;
    border-radius:12px;
    border:1px solid #CFCFDB;

    display:flex;
    flex-direction: column;

    .row{
      display:flex;
      justify-content: space-between;
      padding:15px 0;
      border-bottom:1px solid #EBEBF2;
      align-items: center;
      flex-wrap:wrap;

      #payment-form{
        width:100%;
      }

      form{
        width:100%;
      }

      .card-frame{
        input{
          background-color: white;
          width: -moz-fit-content;
          width: fit-content;
          text-decoration: none;
          display: flex;
          border-radius: 5px;
          border: 0 solid #dedede;
          border-width: 1px;
          padding: 12px;
          width: calc(100% - 24px);
        }
      }

      .pay-now-button{
        cursor:pointer;
        margin:10px;
        padding: 20px 50px;
        border-radius: 30px;
        text-align: center;
        font-weight: bold;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        background-color:white;
      }

      .pay-now-button:hover{
        background-color:hsl(0deg, 0%, 96%);
      }

      .label{
        display:flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      .data{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap:wrap;
      }

      .update{
        font-weight:bold;
      }
    }

    .row:last-child{
      border:none;
    }

    .logo{
      height:100px;
    }

    .sub-logo{
      width:80px;
      padding:3px;
    }

  }

  .button-container{

    display:flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    margin-top:20px;

    .pay-now-button{
      cursor:pointer;
      margin:10px;
      padding: 20px 50px;
      border-radius: 30px;
      color: white;
      text-align: center;
      font-weight: bold;
      width: fit-content;
      border: 2px solid #339966;
      color: #339966;
      text-decoration: none;
      font-size:16px;
      font-family: 'Arial'
    }

    .pay-now-button:first-child{
      margin-left: 0;
    }
  }

  .title{
    text-align: left;
  }
  .info{
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    .field-section{
      flex:4;
      text-align: left;
      margin:10px;

      .section-heading{
        text-align: center;
        font-size:25px;
        font-weight:bold;
        background-color:#323366;
        color:white;
      }

      .form-control{
        display:flex;
        padding: 10px 20px;
        .label{
          font-weight: bold;
          min-width:155px;
        }

        .value{
        }
      }
    }
    .field-section:first-child{
      margin-left:0;
    }

    .field-section:last-child{
      margin-right:0;
    }
  }
  
}

@media screen and (max-width:680px){
  .basic-info-summary{
    .cust-info{
      padding:15px;

    }

    .payment-info{



      .row{
        justify-content: center;

        .label{
          justify-content: space-around;
        }

        .data{
          justify-content: space-around;
        }
      }
    }

    .button-container{
      justify-content: center;
    }
  }
}

@media screen and (max-width:450px){
  .basic-info-summary{
    .info{
      .field-section{
        margin:5px;
        max-width:360px;

        .section-heading{
          font-size:20px;

        }

        .form-control{
          padding:8px 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .label{
            font-size:18px;
          }
          .value{
            font-size:17px;
            text-align: left;
            min-width:155px;
          }
        }

      }
    }

    .cust-info, .payment-info{
    }
  }
}



.party-pack{
  background-color:transparent;
  margin-bottom:20px;
  border-radius: 5px;
  position:relative;

  .delete-button{
    position:absolute;
    width:30px;
    cursor:pointer;
    right:-12px;
    top:-12px;
  }

  .delete-button:hover{
    background-image:linear-gradient(270deg,rgba(255, 0, 0, 0.1), #9e1a7f);
    border-radius:50%;
  }

  .pack-footer{
    color:black;
    border-radius: 0 0 5px 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    min-height:50px;


    .pack-amount{
      flex:1;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      float:right;
      font-size:32px;

      div{
        margin: 0 2px;
      }

      .plus-icon, .minus-icon, .check-icon{
        height:27px;
        padding:3px;
        vertical-align: middle;
        cursor:pointer;
      }

      .quantity-input{
        width: 50px;
        font-size: 20px;
        font-weight: bold;
        color: white;
        background-color: #323366;
        text-align: center;
        border: 1px solid gray;
        border-radius:5px;
      }

      .plus-icon:hover, .minus-icon:hover, .check-icon:hover{
        height:30px;
      }
    }

    .packets-text{
      flex:4;
      display:flex;
      align-items: center;
      justify-content: center;
      margin-left:12px;
      font-size:32px;
    }

    .price-per-pack{
      flex:3;
      .vat-text{
        font-size:14px;
      }
    }

    .price{
      flex:2.5;
      text-align: right;
      font-size:28px;
      font-weight:bold;
      .vat-text{
        font-size:18px;
      }
    }

    
  }

  .items {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .card-container{
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap:wrap;
      width:100%;

      .item-info{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height:90px;
        margin:10px 0 5px 0;
        width:100%;

        .item-image-container{
          flex:2;
          text-align: left;
          height:90px;
          padding-right:10px;
          .item-image{
            width:90px;
            height:90px;
            border-radius:10px;
          }
        }


        .text-box{
          padding-left:5px;
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          height:100%;
          color:#282F41;
          flex:10;

          .description-container{
            margin-top:3px;
            display:flex;
            justify-content: space-between;
            .description{
              font-feature-settings: 'liga' off;
              font-weight:bold;
              font-size:16px;
              line-height: 140%;
              text-align:left;
            }

            .delete-item-icon{
              cursor:pointer;
            }
          }
          .price-container{
            margin-bottom:3px;
            display:flex;
            justify-content: space-between;

            .label{
              display:flex;
              align-items:flex-end;
            }

            .price{
              font-size: 20x;
              font-weight: bold;
              .vat-text{
                font-size:16px;
              }
            }
          }

        }

        .item-tooltip{
          visibility: hidden;
          width: 200px;
          background-color: white;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          margin-left:150px;
          margin-top:-40px;
          position: absolute;
          z-index: 1;
        }

        .item-info:hover .item-tooltip {
          visibility: visible;
        }
      }

      .card{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color:white;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
        border-top:0.5px solid rgba(0,0,0,.03);
        padding:5px;

        min-height:168px;
        margin:5px;
        margin-top:10px;

        .item-image{
          width:80px;
        }

        .item-description{
          padding:0px;
          font-size:14px;
          height:32px;
        }
        .swap-button{
          width:28px;
          height:28px;
          padding:3px;
          cursor:pointer;
        }
      }
    }
  }

  .pack-amount-container{
    display:flex;
    justify-content: center;
    align-items: center;
    padding-bottom:10px;
    padding-top:20px;

    .label{
      flex:6;
      text-align: left;
    }
    .pack-amount{
      flex:6;
      display:flex;
      align-items: center;
      justify-content: flex-end;
      float:right;
      font-size:32px;
      position: relative;

      .grey-container{
        position:relative;
        height:35px;
        border-radius:8px;
        background-color: white;
        border: 1px solid #CDCDCD;
        width:120px;
        padding:8px;
        div{
          margin: 0 2px;
        }
  
        .plus-icon, .minus-icon, .check-icon{
          width:18px;
          padding:3px;
          vertical-align: middle;
          cursor:pointer;
          position:absolute;
          top:12px;
        }

        .plus-icon{
          left:105px;
        }

        .check-icon{
          right:40px;
        }

        .minus-icon{
          left:5px;
          top:20px;
        }

        .input-container{
          display:flex;
          justify-content: center;
          align-items: center;
          .quantity-input{
            width: 50px;
            height:35px;
            font-size: 20px;
            font-weight: bold;
            color: #282F41;
            background-color: white;
            text-align: center;
            border: 0px;
            border-radius:5px;
            padding:0;
          }
        }
  

  
        .plus-icon:hover, .minus-icon:hover, .check-icon:hover{
        }
      }



    }
  }


  .pack-price-container{
    display:flex;
    justify-content: center;
    align-items: center;
    padding-bottom:10px;
    border-bottom:1px solid #99CCCC;

    .label{
      flex:6;
      text-align: left;
    }
    .pack-price{
      color:#282F41;
      flex:6;
      display:flex;
      align-items: center;
      justify-content: flex-end;
      float:right;
      font-size:20px;
      position: relative;

      



    }
  }

  .pack-details{
    display:inline-flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    margin:5px 0 0 0;
    align-items: center;
    color:#323366;
    color: #282F41;
    justify-content: space-between;



    .name{
      font-size:25px;
      text-align: left;
      vertical-align: middle;
      font-weight:bold;
      padding-bottom:10px;
    }

    .button-container{
      display:flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px 0 5px;

      .delete-button{
        background-image:linear-gradient(270deg,rgba(255, 0, 0, 0.671), #9e1a7f);
        float:right;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:20px;
        font-size:14px;
        color:white;
        text-decoration: None;
        cursor:pointer;
      }

      .update-button{
        float:right;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:20px;
        font-size:14px;
        color:white;
        text-decoration: None;
        cursor:pointer;
      }
    }

    .options-container{
      display:flex;
      .delete-pack-text, .edit-pack-text{
        margin:0 7px;
        color:#686D7A;
        cursor:pointer;
        text-decoration: underline;
      }
    }

    .delete-item-accessory{
      width:100%;
    }
  }
}

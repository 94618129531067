.create-pack-min{
  background-color:#f5f5f5;
  min-height:calc(100vh - 80px);
  .no-packs{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    p{
      text-align: center;
      padding-bottom:15px;
    }
    .reset-button{
      padding: 20px 30px;
      border-radius: 30px;
      color: white;
      text-align: center;
      font-weight: bold;
      width: fit-content;
      border: 2px solid #339966;
      color: #339966;
      text-decoration: none;
      cursor:pointer;
    }
  }
  .back-to-top{
    cursor:pointer;
    position:fixed;
    z-index:100;
    bottom:0px;
    right:0px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:white;
    padding:5px;
    font-weight:bold;
    background-color:#323366;
    border-radius:15px 0 0 0;
    .text{
      font-size:20px;
    }
    .icon{
      width:40px;
      height:40px;
      color:white;
      fill:#fff;
      transform: rotate(180deg);
    }
  }

  .heading{
    text-align: center;
    padding:30px;
    h2{
      margin-top:0px;
      margin-bottom:0px;
    }
    p{
      text-align: center;
      max-width:620px;
      margin:0 auto;
    }
  }

  .item-count{
    text-align:center;
  }

  .create-pack-container{
    display:flex;
    align-items: flex-start;
    width:100%;
    max-width:1600px;
    margin:0 auto;
    justify-content: center;
    
    .item-container{
      display:inline-flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      flex:8;

      .heading{
        width:100%;
        text-align: center;
        color:#323366;
        .title{
          font-size:25px;
        }
        margin-bottom:5px;
      }

      ::-webkit-scrollbar {
        width: 8px;
        height:12px;
        position:absolute;
        margin:10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius:10px;
        margin-top:18px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #323366;
        border-radius:10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .infinite-scroll{
        padding-top:0;
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        height:150vh;
      }



      .card{
        flex:4;
        margin-bottom:15px;
        .carousel-wrapper{
          display:flex;
          justify-content: center;
          border: 1px solid transparent;
          position:relative;
          margin:15px 15px 8px 15px;
          background-color:white;
          box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
          border-radius:10px;
          padding-left:5px;
          height:fit-content;
          padding:30px 70px 30px 70px;
          width:auto;
  
          .confetti-animation{
            width:1000px;
          }
  
          .item-image-container{
            position:relative;
            text-align: left;
            .item-image{
              width:200px;
              height:200px;
              padding-bottom:10px;
            }

            .item-image:hover{
              cursor: -moz-zoom-in; 
              cursor: -webkit-zoom-in; 
              cursor: zoom-in;
            }
  
            .magnifying-glass{
              width:30px;
              height:30px;
              transition: .5s ease;
              opacity: 0;
              position: absolute;
              top: 20px;
              left: 20px;
              -ms-transform: translate(-50%, -50%);
              text-align: center;
            }
            
          }
  
          .item-image:hover {
            opacity: 0.8;
          }
  
          .select-container{
            display:flex;
            justify-content: center;
            align-items: center;
            right:-15px;
            top:-15px;
            margin-right:0;
            z-index:10;
            border: 1px solid #323366;
            background-color:#fff;
            color:white;
            width:30px;
            height:30px;
            border-radius:25px;
            font-size:30px;
            z-index:10;
  
            .tick, .plus{
              width:18px;
              height:18px;
            }
  
          }
  
          .vat-text{
            font-size:14px;
            font-weight:400;
          }
  
          @media(max-width:768px){
            flex:12;
          }
  
          .custom-dot-list{
            li{
              z-index:3;
            }
          }
  
          .carousel-container{
            padding-bottom:20px;
          }
  
          .item-info{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height:70px;
            background-color:white;

            position:relative;
            .divider{
              border-top:1px solid #99CCCC;
              width:100%;
              top:-6px;
              padding-bottom:15px;
            }
  
            .item-image-container{
              flex:2;
              text-align: left;
              .item-image{
                width:70px;
                height:70px;
                border-radius:5px;
              }
            }
  
  
            .text-box{
              flex:10;
              text-align: left;
              font-size:16px;
              color:#323366;
            }
  
  
  
            .item-tooltip{
              visibility: hidden;
              width: 200px;
              background-color: white;
              text-align: center;
              border-radius: 6px;
              padding: 5px 0;
              margin-left:150px;
              margin-top:-40px;
              position: absolute;
              z-index: 1;
            }
  
            .item-info:hover .item-tooltip {
              visibility: visible;
            }
          }
  
          .add-to-pack-button{
            background-color:rgb(153 204 204);
            left:calc(50% - 80px);
            bottom:-60px;
            color:white;
            padding:8px 20px;
            border-radius:30px;
            cursor:pointer;
            font-size:18px;
            white-space: nowrap;
            width:fit-content;
            margin:15px auto 0 auto;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
            display:flex;
            height:34px;
            align-items:center;
            .add-short-text{
              display:none;
            }
            .plus-container{
              position:relative;
              width:18px;
              .plus{
                position:absolute;
                font-size:28px;
                top:-18px;
              }
            }
          }

          .added-to-pack-button{
            display:flex;
            align-items:center;
            justify-content: center;
            border: 2px solid rgb(153 204 204);
            background-color: #336699;
            left:0px;
            bottom:-60px;
            color:white;
            padding:6px 18px;
            border-radius:30px;
            cursor:pointer;
            font-size:18px;
            white-space: nowrap;
            width:fit-content;
            margin:15px auto 0 auto;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
            display:flex;
            height:34px;
            align-items:center;
            .remove-short-text{
              display:none;
            }
            .tick-container{
              position:relative;
              width:20px;
              .tick{
                top:-10px;
                position:absolute;
                width:20px;
              }
            }
          }

          .add-to-pack-button:hover, .added-to-pack-button:hover{
            box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
          }
        }
  
  
        .item-info{
          display:flex;
          border-radius:5px;
          
  
          .text-box{
            text-align: left;
            display:flex;
            flex-direction: column;
            .description{
              margin:0;
              min-height:46px;
            }
            .price{
              font-weight:bold;
              font-size:20px;
            }

            .vat-text{
              font-weight: 100;
            }
          }
        }

        .selected{
          border: 2px solid #99CCCC;
        }
      }

      


    }

    .create-preview-container{
      position:sticky;
      top:80px;
    }
  }
  .background-image{
    position: absolute;
    width:100%;
    background-size:cover;
    object-fit:cover;
    left:0;
    z-index:-2;
    opacity:.5;
  }

  .content{
    display:flex;
    flex-direction: column;
    .header{
      text-align: left;
      margin-bottom:50px;

      .title{
        margin-top:20px;
        font-size:45px;
        color:#212121;
      }

      .subtitle{
        font-size:25px;
        color:#212121;
      }
    }

    .error{
      margin:0 auto;
      font-size:26px;
      color:#323366;

    }

    .slider-value{
      font-size:25px;
      margin-bottom:7px;
    }

    .slider-container{
      margin: 0 auto;
      width:60%;
      margin-bottom:50px;
    }

    .transition-container{
      height:100vw;
      overflow:hidden;
      height:100%;
      display:flex;
    }
  }
}


@media only screen and (max-width:1400px){
  .create-pack-min{
    .create-pack-container{


      .item-container{
        .carousel-wrapper{
          min-width:110px;
          margin: 0px 7px 7px 7px;
          .item-info{
            .text-box{
              .description{
                font-size:15px;
              }

              .price{
                font-size:16px;

                .vat-text{
                  font-size:11px;
                }
              }
              
            }
            
          }
        }
      }
    }
  }
}

@media only screen and (max-width:1116px){
  .create-pack-min{
    .create-pack-container{
      .item-container{
        .card{
          .carousel-wrapper{
            padding:20px 30px 10px 30px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width:1000px){
  .create-pack-min{
    .create-pack-container{
      .item-container{
        margin:0;
        .infinite-scroll{
          padding:0;
        }

        .card{
          .carousel-wrapper{
            margin:15px;
            padding-left:0px;
            padding: 5px 10px 12px 10px;
          }
        }

      }

      .create-preview-container{
        margin:0 auto;
        margin:10px 0;
        position:inherit;
      }
    }
  }
}

@media only screen and (max-width:768px){
  .create-pack-min{
    .heading{
      padding-bottom:5px;
    }
  }
}



@media only screen and (max-width:584px){
  .create-pack-min{
    .create-pack-container{
      .item-container{
        .infinite-scroll{
        }

        .card{
          .carousel-wrapper{
            padding:0;
            margin:0 10px;
            .item-image-container{
              display:flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .item-image{
                width:30vw;
                height:auto;
                padding-top:8px;
              }

              .item-info{
                padding:10px;

                .divider{
                  width:90%;
                }


              }
            }
            .add-to-pack-button{
              margin-bottom:10px;
              margin-top:0;
              font-size:15px;
            }
            .added-to-pack-button{
              margin-bottom:10px;
              margin-top:0;
              font-size:14px;
              padding:6px 10px;

            }
          }
        }

      }

      .create-preview-container{
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .create-pack-min{
    .create-pack-container{
      .item-container{
        .card{
          .carousel-wrapper{
            margin:0 3px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .create-pack-min{
    .heading{
      padding:15px 15px 5px 15px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .create-pack-min{
    .create-pack-container{
      .item-container{
        .card{
          min-width:150px;
          margin-left:2px;
          margin-right:2px;
          .carousel-wrapper{
            .added-to-pack-button{
              .remove-long-text{
                display:none;
              }
              .remove-short-text{
                display:block;
              }
            }
            .add-to-pack-button{
              .add-long-text{
                display:none;
              }
              .add-short-text{
                display:block;
              }
            }
          }
        }
      }
    }
  }
}
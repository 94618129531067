.account-page-wrapper{
  padding:50px;
  background-color:white;
  border-radius:5px;
  .account-heading{
    color: #282F41;
    font-size:25px;
    font-weight: 700;
    padding-bottom:15px;
  }

}

@media only screen and (max-width:1000px){
  .account-page-wrapper{
    padding:16px;
  }
}

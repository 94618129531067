.auth-heading{
    .heading-container{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:80px;
        background-color:#f5f5f5;
        // padding-bottom:50px;

        .heading{
            font-style: normal;
            font-weight: 700;
            font-size: 55px;
            line-height: 110%;
            margin-bottom:30px;
            text-align: center;
            color:#282F41;
        }

        .sub-heading{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
        }
    }
}

@media only screen and (max-width:550px){
    .auth-heading{
        .heading-container{
            // padding-left:50px;
            // padding-right:50px;
            .heading{
                font-size:45px;
            }
        }
    }
}

@media only screen and (max-width:640px){
    .auth-heading{
        .heading-container{
            padding-left:5vw;
            padding-right:5vw;
        }
    }
}

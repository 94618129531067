.account-heading{
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-bottom:1px #EBEBF2 solid;
  margin-bottom:8px;
  .title{
    flex:6;
    text-align: left;
    color:#282F41;
    margin: 10px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
  }

  .link-container{
    flex:6;
    max-width:300px;
    text-align: right;

    .link{
      font-weight: 400;
      font-size: 16px;
      a{
        font-style: normal;

        color:#686D7A;
        text-decoration: underline;
      }
    }
    
  }
}
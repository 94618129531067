.header-component{
  width:100%;
  height:80px;
  background-color:white;
  z-index:150;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
  position:fixed;
  top:0;
  .navbar{
    margin:0 auto;
    margin-left:10vw;
    margin-right:10vw;
    display:flex;
    align-items:center;

    .logo{
      padding:10px;
    }

    .desktop-view{
      display:flex;
      align-items:center;
      width:100%;

      .nav-links{
        width:100%;
        list-style-type: none;
        margin:0;

        padding:0;
        display:flex;
        white-space: nowrap;

        .nav-link{
          float:left;
          text-decoration: none;
          margin-left:1vw;
          margin-right:1vw;
          font-weight: bold;

        }

        .build-pack-link:hover{
          color:#339966;
        }

        .pick-pack-link:hover{
          color:#E084A3;
        }

        .about-link:hover{
          color:#999933;
        }

        .contact-link:hover{
          color:#99CC66;
        }
      }

      .auth-list{
        list-style-type: none;
        margin:0;
        padding: 0;
        display:flex;
        align-items: center;

        .underlay{
          position:absolute;
          left:0;
          top:0;
          height:100vh;
          width:100vw;
          z-index:200;
        }
        
        .account-icon-container{
          position: relative;
        }
        .nav-link{

          .cart-button{
            cursor:pointer;
          }


          .account-icon{
            height:29.25px;
            padding:10px 10px 7px 10px;
            cursor:pointer;
          }
          .account-menu{
            display:none;


            .item-list{
              display:flex;
              flex-direction: column;
              .item{
                margin:0;
                text-decoration: none;
                font-size:16px;
                color:#282F41;
                text-align: left!important;
                padding:3px 0;
              }
  
              .item:hover{
                font-weight:800;
                cursor:pointer;
              }
  
              .my-account:hover, .signup:hover{
                color:#339966;
              }
  
              .logout:hover, .login:hover{
                color:#E084A3;
              }
            }
          }

          .account-open{
            width:120px;
            display:block;
            position:absolute;
            z-index:201;
            left:-110px;
            background-color:white;
            padding:15px;
            border-radius:10px;
            border:1px solid #CDCDCD;
          }
        }

        .profile-icon{
          width:32px;
          margin-left:10px;
        }

        .cart-container{
          position:relative;
          .cart-icon{
            width:45px;
            padding: 10px;
          }

          .circle{
            position:absolute;
            top:12px;
            right:10px;
            background-color:#E084A3;
            border-radius:15px;
            width:18px;
            height:18px;
            font-weight:bold;
            display:flex;
            justify-content: center;
            align-items: center;
            .pack-amount{
              color:white;
            }
          }

          .total{
            position:absolute;
            background-color:#E084A3;
            font-weight:bold;
            color:white;
            top:47px;
            border-radius:12px;
            padding:1px 8px;
            font-size:14px;
          }
        }


        .nav-links{
          width:100%;
          list-style-type: none;
          margin:0;

          padding:0;
          display:flex;
          white-space: nowrap;

          .nav-link{
            float:left;
            text-decoration: none;
            margin-left:.5vw;
            margin-right:.5vw;
          }
        }
      }
    }

    .login-text{
      cursor: pointer;
      text-decoration:none;
    }
    
    .logout-text{
      cursor: pointer;
      align-items: center;
      margin-left:40px;
      font-weight:bold;
      @media only screen and (max-width:864px){
        margin-left:0px;
      }
    }
    
    .register-text{
      cursor:pointer;
      color: #ff00c4;
      text-align: left;
      text-decoration: none;
    }
    
    .cart-text{
      cursor: pointer;
      align-items: center;
      margin-left:10px;
      a{
        text-decoration: None;
      }
    }
    
    
    .link{
      height:100px;
    }

    .mobile-view{
      width:100%;

      .cart-button{
        cursor:pointer;
      }

      .hamburger-menu{
        z-index:10;
        .hamburger{
          position:fixed;
          width:35px;
          height:35px;
          z-index:10;
          left:5vw;
          top:23px;
        }
      }

      .mobile-list{
        width:100%;
        top:0px;
        margin:0;
        padding:5px;
        right:0;
        position:fixed;
        list-style: none;
        background-color:white;
        z-index:5;
        opacity:0.9;

        .nav-link{
          font-weight:bold;
          text-align: center;
          margin-left:10%;
          padding:6px;
          text-decoration:none;
        }

        .nav-link a{
          text-decoration: none;
        }
      }
    }
  }

  
  @media screen and (max-width: 1280px) {
    .navbar{
      margin-left:5vw;
      margin-right:5vw;
      .nav-links{
        li{
         margin-left:10px;
         margin-right:10px;
       }
      }
    }
  }

  @media screen and (max-width: 1052px) {
    .navbar{
      .nav-links{
        li{
         margin-left:0px;
         margin-right:0px;
       }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .navbar{
      margin-left:15px;
      margin-right:15px;
    }
  }

  @media screen and (max-width: 867px) {
      .navbar{
        .desktop-view{
          .nav-links{
            li{
             margin-left:2px;
             margin-right:2px;
    
           }
           .nav-link{
              margin-right:5px;
            }
          }
        }
      }
  }
  
  @media screen and (max-width: 840px) {
    .navbar{

      justify-content: center;
  
      .nav-links {
        li{
          margin-left:5px;
          margin-right:5px;
        }

      }
  
      .logo{
      }
  
      .mobile-view{
        width:auto;
        flex:none;
        background-color:white;

        .cart-button{
          width:auto;
          position: fixed;
          z-index: 10;
          right: 5vw;
          top: 16px;
          .cart-container{
            width:auto;

            img{
              width:49px;
            }

            .circle{
              position:absolute;
              top:0px;
              right:0px;
              background-color:#E084A3;
              border-radius:15px;
              width:18px;
              height:18px;
              font-weight:bold;
              display:flex;
              justify-content: center;
              align-items: center;
              .pack-amount{
                color:white;
              }
            }
  
            .total{
              position:absolute;
              background-color:#E084A3;
              color:white;
              top:0px;
              right:0px;
              border-radius:12px;
              padding:1px 8px;
              font-size:14px;
            }

          }
        }
      }
    }
  
  }
  
  .mobile-view{
    /* ANIMATIONS */
    .slide-enter {
      z-index:-1;
      transform: translateY(-200%);
    }
    .slide-enter-active {
      z-index:-1;
      transform: translateY(0%);
      transition: transform 500ms ease-in-out;
      position:absolute;
    }
    .slide-exit {
      position:absolute;
      transform: translateY(0%);
    }
    .slide-exit-active {
      transform: translateY(-200%);
      transition: transform 500ms ease-in-out;
    }
  }
}

@media only screen and (max-width:360px){
  .header-component{
    .navbar{
      .logo{
        display:flex;
        align-items: center;
        padding-top:15px;
        img{
          width:60vw;
        }
      }
      .mobile-view{
        .cart-button{
          right:3vw;
        }
        .hamburger-menu{
          .hamburger{
            left:3vw;
          }
        }
      }
    }

  }
}





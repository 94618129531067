.order-success{
    min-height:calc(100vh - 84px);
    display:flex;
    flex-direction: column;
    justify-content: center;



    .container{
      display:flex;
      align-items:center;
      justify-content: center;
      flex-direction: column;
      .fun-loader{
        width:320px;
        height:180px;
        margin:0 auto;
        margin-top:-300px;
      }
      .heading-container{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:30px;
        background-color:#f5f5f5;
        padding-bottom:50px;
  
        .heading{
            font-style: normal;
            font-weight: 700;
            font-size: 61.04px;
            line-height: 110%;
            margin-bottom:10px;
            text-align: center;
        }
  
        .sub-heading{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            a{
              color:rgb(109, 109, 211);
              font-weight: bold;
              text-decoration: underline;
            }
        }
      }
      .text-block{
        margin-top:50px;
        display:flex;
        flex-direction: column;
        .title{
          font-size:35px;
        }
        .sub-title{
          font-size:25px;
        }
        .text{
          font-size:20px;
        }
        .party-images{
          margin-top:40px;
          z-index:-1;
        }
      }
    }
  }

h1{
    font-size:61px;
    font-weight:bold;
    line-height: 110%;
    color:#282f41;
    margin:0;

    @media only screen and (max-width:360px) {
        font-size:44px;
    }
    
}

h2{
    font-size:48.8px;
    line-height: 120%;
    font-weight:bold;
    color:#282f41;

    @media only screen and (max-width:640px) {
        margin-bottom: 0;
        font-size:41px;
    }

    @media only screen and (max-width:360px) {
        font-size:32px;
    }
}

h3{
    font-size:39.06px;
    line-height: 120%;
    font-weight:bold;
    color:#282f41;
    text-align: left;
    margin:10px 0;

    @media only screen and (max-width:360px) {
        font-size:34px;
    }
}

h4{
    font-size:25px;
    font-weight:bold;
    color:#282f41;
}

h5{
    font-size:18px;
    font-weight:bold;
    color:#282f41;
}

p{
    font-size:20px;
    line-height: 140%;
    letter-spacing: -2%;
    color:#282F41;
    margin:0;
    text-align: left;

    @media only screen and (max-width:768px) {
        line-height: 120%;
    }
}

a{
    color:#282F41;
    text-decoration: none;
}
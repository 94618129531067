.spacer{
  padding:25px 0;
}

.order-detail{


.order-detail-header{
  display:flex;
  .title{
    display:flex;
    align-items: center;
    flex:6;
    text-align: left;
    color:#282F41;
    font-weight: bold;
    font-size:25px;
    margin: 10px 0;
    .back-arrow{
      transform: rotate(90deg);
      height:12px;
    }  
  }

  .button-container{
    .pay-button{
        cursor: pointer;
        margin: 10px;
        padding: 20px 50px;
        border-radius: 30px;
        text-align: center;
        font-weight: bold;
        width: -moz-fit-content;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        background-color: white;
        font-size:16px;
    }
  } 
}

.delivery-status, .created-at{
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #686D7A;
  padding:5px 0;
}


.created-at{
  padding-bottom:20px;
  border-bottom: 1px solid #EBEBF2;
}

.party-packs{
  padding-top:20px;
}

.missing-snapshot{
  color:#282F41;
  font-size:25px;
  padding:15px 0;
}

.order-breakdown{
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width:100%;
  .fee{
    color:#282F41;
    display:table-row;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.02em;
    .label{
      display:table-cell;
      padding:0 50px;
      text-align: left;

    }
    .value{
      display:table-cell;
      width:100px;
      text-align: right;
    }
    .total-label, .total-value{
      font-weight:500;
      font-size:25px;
      padding-top:5px;
    }
  }
}


  .detail-container{
    display:flex;
    flex-direction: column;
    border-radius:10px;
    .header{
      border-radius: 10px 10px 0 0;
      display:flex;

      .created-at{
        justify-content: flex-start;
        flex:8;
        font-size:20px;
        font-weight:bold;
        padding:20px;
      }

      .status{
        color:white;
        flex:4;
        font-size:20px;
        font-weight:bold;
        background-color: #3bb54a;
        padding:20px;
        border-radius:10px 10px 0 10px;
      }
    }

    .content{
      margin-bottom:20px;
      box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
      background-color:white;
      padding:30px;
  
      .order-info{
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;

      }

      .shipping-address{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
        margin-bottom:15px;


      }
  
      .table{
        margin: 30px 0;
      } 
    }
  }
  
}

.order-info-heading{
  display:flex;
  align-items: center;
  flex:6;
  text-align: left;
  color:#282F41;
  font-weight: bold;
  font-size:25px;
  margin: 10px 0;
}
.order-info-container{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;

  .column{
    flex:3;
    min-width:180px;
    p{
      font-size:17px;
    }
  }
}



@media only screen and (max-width:500px){
  .order-detail{
    .detail-container{
      .content{
        padding:15px;


      }
    }
  }
}

@media only screen and (max-width:459px){
  .order-info-container{
    justify-content: center;
    .column{
      flex:12;
    }
  }
}


.cart {
  min-height:calc(100vh - 80px);
  margin:0 auto;
  .background-image{
    position: absolute;
    height:100%;
    left:0;
    background-size:cover;
    object-fit:cover;
    z-index:-2;
    opacity:.5;
    max-width: 100%;
  }

  .heading {
    padding:40px 0;
    padding-bottom:15px;
    font-weight: bold;

    .title{
      font-size:40px;
      color:#212121;
    }

    .description{
      font-size:28px;
      color:black;

      .swap-icon-text{
        width:20px;
      }
    }
  }

  .container{
    margin-left:0;
    margin-right:0;

    .cart-container{
      display:flex;
      flex-wrap:wrap;
      width:fit-content;
      margin:0 auto;
      flex-direction: column;

      .content{
        display:flex;
        width: 1400px;
        
        .cart-items{
          width:60%;
          display:flex;
          flex-direction: column;
  
          .cart-card{
  
            .party-pack-container{
              background-color: white;
              padding:25px;
              border-radius:12px;
              margin-bottom: 30px;
              width:92%;
              margin-right:20px;
            }
  
            margin-bottom : 20px;
  
            .header-container{
              .header{
                text-align: left;
                margin:0;
              }
            }
  
            .party-details {
              text-align: left;
              margin:4px;
              .party-detail {
                flex:3;
  
  
              }
            }
  
            .party-packs-text{
              text-align: left;
              margin:4px;
            }
  
  
            .delete-party-button{
              background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
              float:right;
              padding:15px;
              border:none;
              border-radius:5px;
              margin:20px;
              font-size:14px;
              color:white;
              cursor:pointer;
            }
  
            .party-supplies-banner{
              background-color:black;
              box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
              margin-bottom:20px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              height:100px;
              position:relative;
  
              .image{
                position:absolute;
                opacity:0.7;
                flex-shrink: 0;
                width: 100%;
              }
  
              .content{
                display:flex;
                z-index:10;
                justify-content:center;
                align-items: center;
                .text{
                  flex:9;
                  font-size:25px;
                  font-weight:bold;
                  text-align: left;
                  color:white;
                  width:100%;
                  padding-left:15px;
                }
                .button-container{
                  flex:3;
                  .button{
                    background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
                    padding:15px;
                    border:none;
                    min-width:100px;
                    border-radius:5px;
                    font-size:16px;
                    color:white;
                    font-weight:bold;
                  }
                }
              }
  
            }
          }
        }

        .cart-summary{
          width:40%;
        }
      }
    }

    .empty-cart{
      height:calc(100vh - 300px);
      margin:25px;
      justify-content: center;
      align-items: center;
      .text{
        font-size:26px;
        text-align: center;
        margin:20px;
      }
      .button-container{
        display:flex;
        justify-content: center;
        width:100%;
        .create-link{
          background-color:rgb(224, 132, 163);
          border-radius: 30px;
          color: white;
          text-align: center;
          font-weight: bold;
          width: -moz-fit-content;
          width: fit-content;
          padding:20px 30px;
        }
      }
    }



  }
}

.transition-container{
  overflow:hidden;
  height:100%;
  display:flex;
  background-color:#f5f5f5;
}

@media only screen and (max-width: 800px) {
  .cart{
    .container{
      .cart-container{
        flex-direction:column;
        .cart-items{
          width:auto;
          .card-container{
            justify-content: center;
            .card{
            }
          }

        }

      }
    }

  }
}



@media only screen and (max-width: 1500px) {
  .cart .container .cart-container .content {
    width:1200px;
  }
}

@media only screen and (max-width: 1250px) {
  .cart .container .cart-container .content {
    width:1000px;
  }
}

@media only screen and (max-width: 1000px) {
  .cart{
    .container{
      .title{
        margin:0 16px;
      }
      .cart-container{
        .content{
          max-width:calc(100vw - 32px);
          margin: 0 16px;
          display:flex;
          flex-direction:column-reverse;
          justify-content: center;
          align-items:center;
          width:100vw;
          .cart-items{
            width:100%;
            margin: 20px 0;

            .cart-card{
              .party-pack-container{
                margin:0;
                width:auto;
                margin-bottom:20px;

              }
            }
          }
  
          .cart-summary{
            width:100%;

            .cart-summary-container{
              margin:0;
              .card{
                max-width:none;
              }
            }
  
          }
        }
      }
    }
  }
}

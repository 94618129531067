.customer-info{
  min-height: calc(100vh - 80px);

  .profile-container{
    margin-bottom:20px;
    .heading{
      text-align: left;
      margin-left:30px;
    }

    .profile-form{
      display:flex;
      flex-direction: column;

      .address-heading:first-child{
        padding-top:0;
      }

      .address-heading{
        text-align:left;
        font-weight: bold;
        padding:15px 0;
        font-size:25px;
      }
      .form-input{
        display:inline-flex;
        flex-wrap:wrap;
        flex-direction:column;

        .select{
          display:flex;
          border-radius: 5px;
          border:0 solid #dedede;
          border-width: 1px;
          padding:11px;
          width:100%;
          background-color:white;
        }

        .form-section{
          flex:4;
          margin:5px;
          border-radius:15px;
          padding-bottom:10px;

          .halves{
            display:flex;
            .form-control{
              flex:6;
            }
          }

          .thirds{
            display:flex;
            .form-control{
              flex:4;
            }
          }

          .sub-heading{
            text-align: left;
            font-weight: bold;
            padding: 15px 0;
            font-size: 18px;
          }

          .special-instructions-textarea{
            padding:12px;
            width:calc(100% - 24px);
            border-radius: 5px;
            border: 1px solid #dedede;
            font-size:14px;
          }
        }

      }

      .button{
        background-color:#323366;
        padding:0.5rem 0.5rem 0.5rem 0.5rem;
        border:none;
        border-radius:.25rem;
        color:white;
      }

      .form-control{
        padding:5px 5px 5px 0;
        flex:6;

        label{
          display:block;
          text-align:left;
          padding:5px;
          font-size:14px;
          color:#4a5568;
        }

        .email, .password{
          display:flex;
          input{
            flex:8;
          }
          button{
            flex:4;
          }
        }
      }


      .button-container{
        display:flex;
        justify-content: center;
        align-items: center;
        margin: 0px 13px 0px 13px;
        .button{
          flex:6;
          margin:3px;
        }
      }

      .form-submit {
        display:flex;
      }

      .loader{
        width:80px;
        margin:0 auto;
      }
    }
  }
}

@media only screen and (max-width:1000px){
  .customer-info{
    .profile-container{
      .profile-form{
        .form-input{
          .form-section{
            min-width:calc(100vw - 60px);
          }
        }
        .form-submit{
          align-items:center;
          flex-wrap:wrap;
          input{
          }
        }
      }
    }
  }
}

@media only screen and (max-width:390px){
  .customer-info{
    .profile-container{
      .profile-form{

        .form-submit{
          justify-content: center;
          .button-link{
          }
          input{
            margin:0 auto;
          }
        }
      }
    }
  }
}

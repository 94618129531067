
    .cart-summary-container{
      margin-left:20px;

      .card{
        border-radius:12px;
        background-color:white;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
        padding:35px;
        max-width:500px;

        .proceed-button{
          cursor:pointer;
          margin:15px auto 0 auto;
          padding: 20px 30px;
          border-radius: 30px;
          color: white;
          text-align: center;
          font-weight: bold;
          width: fit-content;
          border: 2px solid #339966;
          color: #339966;
          text-decoration: none;
        }
        .header{
          font-size:25px;
          text-align:left;
          color:#282F41;
          margin-bottom:10px;
        }

        .charge-shipping{
          color:rgb(214, 72, 15);
          padding-bottom:10px;
        }
  

        .content{
          display:flex;
          flex-direction: column;
          align-items: left;
          justify-content: left;
          text-align: left;
          padding:15px;
          .text{
            padding:3px;
            .label{

            }
            .value{
              float:right;
            }
          }
      }

      }
      .checkout-form{
        display:flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: wrap;
        padding-top:20px;
        @media(max-width:1360px) {
          .date-field{
            margin-top:10px;

            flex-direction: column;

            .label{
              padding-bottom:10px;
            }
          }
        }

        .date-field{
          flex:9;
          display:flex;
          width:80%;
          flex-direction: column;;
          .label{
            padding-left:2px;
            padding-bottom:5px;
            font-size:24px;
            color:#323366;
            flex:4;
            text-align:left;
            font-weight:bold;
          }

          input{
            display:flex;
            flex:3;
            border-radius:10px;
            border:5px solid #323366;
            border-width: 1px;
            padding:14px;
            font-size:17px;
            font-weight:bold;
            cursor:pointer;
          }

          input::placeholder{
            color:#98159c;
          }

          .date-picker{
            position:relative;
            overflow:hidden;
          }
          .date-picker::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
          }
        }
        .submit-button-container{
          min-width: 200px;
          flex:6;
          align-items: baseline;
          .submit-button{
            background-color:#323366;
            padding:15px;
            border:none;
            border-radius:25px;
            font-size:20px;
            font-weight:bold;
            color:white;
            float:right;
            cursor:pointer;
            @media(max-width: 1268px){
              padding:12px;
            }
          }
        }

      }

      .add-party-pack-button{
        background-color:#323366;
        padding:15px;
        border:none;
        border-radius:25px;
        margin:20px 0;
        font-size:20px;
        font-weight:bold;
        color:white;
        text-decoration: None;
        cursor:pointer;
      }
    }
    
    .cart-summary-component{
      width:100%;
      display:flex;
      flex-direction: column;

      .party-packs{
        display:flex;
        flex-direction: column;
        border-top: 1px solid #EBEBF2;
        border-bottom: 1px solid #EBEBF2;
        padding: 15px 0;
        .party-pack{
          display:flex;
          margin:0;
          width:100%;
          padding:5px 0;

          div{
            font-size:20px;
            color:#282F41;
          }
          
          .name{
            flex:7;
            display:flex;
            align-items: center;
          }
  
          .quantity{
            flex:2;
          }
  
          .price{
            flex:3;
            text-align: right;
            display:flex;
            align-items: center;
          }
        }
      }



      .breakdown{
        display:flex;
        flex-direction: column;
        div{
          display:flex;
          justify-content: space-between;
          color:#282F41;
          padding:4px 0;
          .label{}
        }
        .subtotal{
          font-size:20px;
        }

        .shipping-cost{
          font-size:20px;
        }

        .voucher-deductions{
          .label{
            display:flex;
            align-items: center;
            .delete-voucher-icon{
              cursor:pointer;

            }
          }
        }

        // .voucher-deductions{
        //   font-size:16px;
        // }
  
        .vat{
          font-size:20px;
        }
  
        .total{
          font-size: 25px;
          font-weight:bold;
        }
      }
    }

    .redeem-voucher-container{
      display:flex;
      justify-content: space-between;
      align-items: center;
      margin-top:20px;
      .input-container{
        margin-right:25px;
        flex:7;

      }
      .button-container{
        width:100%;
        flex:5;

        display:flex;
        justify-content: flex-end;
        flex-wrap:wrap;
    
        .redeem-voucher-button{
          cursor: pointer;
          margin: 10px;
          padding: 15px 15px;
          border-radius: 30px;
          text-align: center;
          font-weight: bold;
          width: -moz-fit-content;
          width: fit-content;
          border: 2px solid #339966;
          color: #339966;
          text-decoration: none;
          background-color: white;
          margin-left:0;
          font-size:16px;
        }
    
        .redeem-voucher-button:hover{
          background-color:hsl(0deg, 0%, 96%);
        }
    
      }
    }




@media only screen and (max-width:1428px) {
  .cart-summary-container{
    .card{
      padding:25px;
    }
    .cart-summary-component{
      .party-packs{
        .party-pack{
          div{
            font-size:16px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width:1000px) {
  .mobile-view{
    top:80px;
    left:0;
    margin:0;
    z-index:100000;
    background-color:#f6f6f8;
    .card{
      padding:0;
      width:100%;
      max-width:calc(100vw - 50px);
      background-color:#f6f6f8;
      .cart-summary-component{
        width:100%;
      }
    }
    .mobile-banner{
      display:flex;
      background-color:#f6f6f8;
      padding:10px 0;
      justify-content: center;
      align-items:center;
      border-bottom:1px solid #EBEBF2;

      .cart-icon{
      }

      .text{
        display:flex;
      }

      .dropdown-arrow{
        padding-left:7px;
      }

      .rotated{
        img{
          transform: rotate(180deg);
        }
      }

      .total{
        flex:1;
        text-align: right;
      }

    }
  }
}


@media only screen and (max-width:375px){
  .mobile-view{
    .mobile-banner{
      .text, .total{
        font-size:14px;
      }
    }
  }
}

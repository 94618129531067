.reset-filters{
    position:relative;
    .filter-heading{
        background-color:white;
        border: 2px solid rgb(153 204 204);
        left:0px;
        bottom:-60px;
        color:#686D7A;
        padding:17px 23px;
        border-radius:30px;
        font-weight:bold;
        letter-spacing: 1px;
        cursor:pointer;
        font-size:16px;
        white-space: nowrap;
        width:fit-content;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */

        img{
          filter: invert(46%) sepia(4%) saturate(1399%) hue-rotate(186deg) brightness(90%) contrast(85%);
          padding-left:10px;
        }

        .filter-number{
            width:20px;
            height:20px;
            font-size:10px;
            border-radius:50%;
            border: 1px solid white;
            background-color:#FFCC33;
            display:flex;
            justify-content: center;
            align-items: center;
            color:white;
            position:absolute;
            top:0px;
            right:0;
        }
     

    }

    .filter-heading:hover{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }



    .active{
        border: 2px solid #a6a5f7!important;
        color:#a6a5f7;
        img{
          filter: invert(84%) sepia(99%) saturate(2657%) hue-rotate(324deg) brightness(99%) contrast(102%);
        }
    } 

    .other-filter-content{
        top:72px;
        background-color: white;
        padding:30px;
        width:400px;
        border-radius:10px;
        border: 1px solid #CDCDCD;
        position: absolute;
        z-index:100;
        
        
    }
}

.other-container{
  .other-element-container{
    display:flex;
    align-items: center;

    .text-label{
      padding-left:15px;
    }

    .checkbox-container{
      padding:2px;

      input[type="checkbox"] { /* change "blue" browser chrome to yellow */
        color: #323366;
        visibility: hidden;
      }

      .checkbox {
        width: 24px;
        height: 24px;
        border:1px solid #686D7A;
        border-radius:5px;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
        margin: 5px auto;
        position: relative;
      }

      .checkbox label {
        cursor: pointer;
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0px;
        top: 0px;
      }

      .checkbox label:after {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        background: #339966;
        border-radius:5px;
        left:-1px;
        top:-1px;
      }

      .checkbox label:hover::after {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
        filter: alpha(opacity=30);
        opacity: 0.3;
      }

      .checkbox input[type=checkbox]:checked + label:after {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1;
      }
    }

    label{
      font-size:20px;
    }
  }
}



@media only screen and (max-width:1250px){
  .other{
    margin: 10px 15px;
  }
}
.login-component{
  color:#282F41;
  .container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-block{
      max-width:400px;
      text-align: center;
      margin-bottom:20px;
      .title{
        padding-bottom:10px;
        font-weight: 700;
        font-size: 61.04px;
        color:#282F41;
        line-height: 110%;
      }

      .description{
        margin-bottom:10px;
      }

      .close-button-container{
        width:100%;
        margin-top:10px;

        .close-button{
          float:right;
          background-color:#ff00c4;
          padding:1rem 1.5rem 1rem 1.5rem;
          border:none;
          border-radius:.25rem;
          color:white;
        }
      }
    }
  }

  .signup-form{
    display:flex;
    flex-direction: column;
    min-width:400px;
    margin: 0 auto;
    border-radius:10px;

    .forgot-password{
      display:flex;
      justify-content: center;
      padding-bottom:30px;
    }

    .form-input{
      display:flex;
      flex-direction:column;
      width:100%;
      max-width:450px;

      input{
        display:flex;
        border-radius:5px;
        border:0 solid #dedede;
        border-width: 1px;
        padding:10px 0px 10px 10px;
        padding:15px 10px;
        width:92%;
      }


      .form-control{
        position:relative;
        padding:10px 0;
        label{
          position:absolute;
          display:block;
          text-align:left;
          padding:5px;
          font-size:14px;
          color:#4a5568;
        }
      }

      .form-control:last-child{
        input{
          margin-bottom:0;
        }
      }
    }


    .button-container {
      display:flex;
      justify-content: center;

      input, a{
        padding: 20px 70px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        cursor:pointer;
        background-color:transparent;
      }



      a{
        background-color:white;
        color:#323366;
      }
    }

    .loader{
      width:80px;
      margin:0 auto;
    }
  }

  .forgot-or-register{
    display:flex;
    flex-direction:column;
    a{
      text-decoration: none;
      color:#323366;
      padding:5px;
    }
  }

}

.cart-orientation{

  .login-container {

    justify-content: flex-start;
    align-items: flex-start;

    .title-block {
      text-align: left;
      .title {
        font-size:25px;
        font-weight: bold;
      }

      .register-cart{
        a{
          color:#282F41;
          font-weight:bold;
          text-decoration: underline;
        }
      }
    }
  }

  .signup-form {
    margin:0;
    .button-container{
      justify-content: flex-start;
    }

    .forgot-password {
      justify-content: flex-start;
    }
    .form-input{
      input{
      }
    }

    .form-control:last-child{
      input{
        margin-bottom:10px;
      }
    }
  }

  .register-login{
    display:none;
  }
}

@media only screen and (max-width:500px){
  .login-component{
    .login-container{
      width:calc(100vw - 90px);
      .signup-form{
        min-width:220px;
      }
    }

  }
}

.contact-us{
    min-height:100vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    max-width:1600px;
    margin:0 auto;
    .container{
        display:flex;
        justify-content: center;
        width:100%;
        flex-wrap:wrap;
        .block{
            max-width:400px;
            width:100%;
            min-width:300px;

            .link{
                margin-top:10px;
                text-decoration: none;
            }

            .heading{
                font-style: normal;
                font-weight: 800;
                font-size: 30px;
                line-height: 140%;

            }
            .phone-number-container{
                margin: 32px 0;
                .phone-number{
                    font-size:20px;
                }
            }

            .connect-with-us{
                margin: 14px 0;
                margin-bottom:30px;
                .social-logos{
                    display:flex;
                    img{
                        width:45px;
                        padding:0 15px 0 0;
                    }
                }
            }
        }
        .text-container{
            flex:6;
            .heading{
                font-style: normal;
                font-weight: 700;
                font-size: 61.04px;
                line-height: 110%;
            }
            .sub-heading{
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 140%;
                padding:20px 0;
            }
            .mini-heading{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                padding:15px 0;
            }

            .call-us{
                .phone-number{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 140%;
                    letter-spacing: -0.02em;
                }
            }

            .connect-with-us{
                .social-logos{
                    display:flex;
                    img{
                        width:50px;
                        padding:0 15px 0 0;
                    }
                }
            }

            .connect-faq{
                margin-top:60px;
            }
        }
        .form-container{
            flex:6;
            .heading{
                font-style: normal;
                font-weight: 700;
                font-size: 39.06px;
                line-height: 120%;
                padding-bottom:30px;
            }

            .text-box-input{
                input{
                    min-height:200px;
                }
            }

                input{
                    margin: 20px 0;
                }

                .text-area-container{
                    margin: 20px 0;
                    .message-length{
                        padding:5px 5px 0 5px;
                    }
                    textarea{
                        width:100%;
                        padding:12px;
                        background-color: white;
                        width: -moz-fit-content;
                        width: fit-content;
                        text-decoration: none;
                        display: flex;
                        border-radius: 5px;
                        border: 0 solid #dedede;
                        border-width: 1px;
                        padding: 12px;
                        width: calc(100% - 24px);
                    }
                }



                .submit-button{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 19.5px 32px;
                    gap: 10px;
                    border-radius:43px;
                    background-color: #339966;
                    cursor:pointer;
                    font-weight:bold;
                    color:white;
                    width:fit-content;
                }
        }
    }
}

@media only screen and (max-width:888px){
    .contact-us{
        .container{
            .block{
                display:flex;
                flex-direction: column;
                .form-container{
                    width:100%;
                }
            }
        }
    }
}


@media only screen and (max-width:430px){
    .contact-us{
        .container{
            .block{
                padding:0 30px;
                min-width:250px;
            }
        }
    }
}
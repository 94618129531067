.continue-shopping-modal{
  position:absolute;
  z-index:151;
  width:100%;
  height:100%;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  justify-content: center;
  align-items: center;

  .underlay{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:200vh;
    background-color: rgba(0,0,0,0.33);
  }

  .main{
    z-index: 11;
    position: fixed;
    top:50%;
    border-radius:15px;
    background-color:#fff;
    width:50%;
    max-width:600px;
    height:auto;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    max-height:calc(100vh - 10px);
    padding:0px 50px 50px 50px;

    .text{
      text-align: center;
    }

    .close-container{
      position: absolute;
      z-index:12;
      right:0;
      text-align: right;
      padding:5px;
      cursor:pointer;
      .close-image{
        width:35px;
        height:35px;
      }

    }

    .button-container{
      display:flex;
      justify-content: center;
      margin: 0 50px;
      .checkout-button{
        cursor: pointer;
        margin: 15px auto 0 auto;
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: -moz-fit-content;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
      }
      .continue-shopping-button{
        cursor: pointer;
        margin: 15px auto 0 auto;
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: -moz-fit-content;
        width: fit-content;
        border: 2px solid #4592c2;
        color: #4592c2;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width:1500px){
  .continue-shopping-modal{
    .main{

    }
  }
}


@media only screen and (max-width:1240px){
  .continue-shopping-modal{
    .main{

    }
  }
}

@media only screen and (max-width:1000px){
  .continue-shopping-modal{
    .main{
      .button-container{
        margin: 0 10px;
        flex-wrap:wrap;
      }

    }
  }
}



@media only screen and (max-width:787px){
  .continue-shopping-modal{
    .main{
      .text{
        margin-bottom:10px;
      }
    }
  }
}
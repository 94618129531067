

  .premade-pack{
    /* width */
    height:fit-content;
    border: 1px solid transparent;

    .description{
      margin:0;
      width:100%;
      text-align: center;
      padding-bottom:10px;
    }
    .divider{
      margin:0 auto;
      border-top:1px solid #99CCCC;
      width:400px;
    }

    .exploding-confetti{
      position:absolute;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height:12px;
      position:absolute;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius:10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #99CCCC;
      border-radius:10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }


    padding:25px;
    position:relative;
    flex:6;
    max-width:420px;
    margin:0 auto;
    margin-bottom:20px;
    margin-left: 10px;
    margin-right:10px;
    min-width:370px;
    background-color:white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    border-radius:10px;
    height:380px;

    .select-container{
      display:flex;
      justify-content: center;
      align-items: center;
      position:absolute;
      right:-15px;
      top:-15px;
      margin-right:0;
      z-index:10;
      border: 2px solid #99CCCC;
      background-color:#fff;
      color:white;
      width:40px;
      height:40px;
      border-radius:25px;
      font-size:40px;

      .tick, .plus{
        width:25px;
        height:25px;
      }

    }

    .out-of-stock{
      position:absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom:40px;
      padding:0 10px 0 10px;
      height:40px;
      background-color:#db4d4d;
      z-index:10;
      display:flex;
      justify-content: center;
      align-items: center;
      .text{
        flex: 7;
        font-size: 25px;
        text-align: left;
        color:white;
      }
      .sad-face{
        width:25px;
        height:25px;
        margin-left:10px;
      }
    }



    .items{
      height:210px;
      overflow: hidden;
      margin-bottom:5px;
    }

    .card-footer{
      display:flex;
      justify-content: center;
      align-items: center;
      border-radius:0 0 5px 5px;
      height:40px;
      color:white;
      padding:0 10px 0 10px;

      .name{
        flex:7;
        font-size:25px;
        text-align: left;
      }

      .price{
        flex:5;
        text-align: right;
        font-size:28px;
        font-weight:bold;
      }
    }

    .item-info{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height:70px;
      background-color:white;



      .item-image-container{
        position:relative;
        width:100px;
        height:100px;
        flex:2;
        text-align: left;
        .item-image{
          width:100px;
          height:100px;
        }

        .magnifying-glass{
          width:30px;
          height:30px;
          transition: .5s ease;
          opacity: 0;
          position: absolute;
          top: 20px;
          left: 20px;
          -ms-transform: translate(-50%, -50%);
          text-align: center;
        }
        
      }

      .item-image-container:hover .item-image {
        opacity: 0.8;
      }
      
      .item-image-container:hover .magnifying-glass {
        opacity: 0.9;
      }


      .text-box{
        flex:8;
        text-align: center;
        font-size:14px;
        padding:12px;
      }



      .item-tooltip{
        visibility: hidden;
        width: 200px;
        background-color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        margin-left:150px;
        margin-top:-40px;
        position: absolute;
        z-index: 1;
      }

      .item-info:hover .item-tooltip {
        visibility: visible;
      }
    }

    .footer-item-info{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color:white;
      padding:0px 30px 0px 30px;

      position:relative;
      .divider{
        border-top:1px solid #99CCCC;
        width:400px;
      }

      .item-image-container{
        flex:2;
        text-align: left;
        .item-image{
          width:70px;
          height:70px;
          border-radius:5px;
        }
      }


      .text-box{
        display:flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        font-size:16px;
        .description{
          margin:0;
        }
      }

      .customise-pack-button{
        text-decoration: underline;
        cursor:pointer;
        color:#339966;
        font-weight:bold;
        margin-top:16px;
      }

      .button-container{
        width:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        .add-to-pack-button{
          display:flex;
          align-items:center;
          background-color:rgb(153 204 204);
          left:calc(50% - 80px);
          bottom:-60px;
          color:white;
          padding:8px 20px;
          height:34px;
          border-radius:0 25px 25px 0;
          cursor:pointer;
          font-size:18px;
          white-space: nowrap;
          width:fit-content;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
          .plus-container{
            position:relative;
            width:18px;
            .plus{
              position:absolute;
              font-size:28px;
              top:-18px;
            }
          }
          

        }

        .added-to-pack-button{
          border: 2px solid rgb(153 204 204);
          left:0px;
          bottom:-60px;
          color:rgb(153 204 204);
          padding:8px 20px;
          border-radius:30px;
          cursor:pointer;
          font-size:18px;
          white-space: nowrap;
          width:fit-content;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
          background-color: #336699;
          height:34px;
          display:flex;
          align-items:center;
          .tick-container{
            position:relative;
            width:20px;
            .tick{
              top:-9px;
              position:absolute;
              width:20px;
            }
          }

        }
      }

      .button-container:hover{
        .added-to-pack-button{
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        .add-to-pack-button{
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }



      .item-tooltip{
        visibility: hidden;
        width: 200px;
        background-color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        margin-left:150px;
        margin-top:-40px;
        position: absolute;
        z-index: 1;
      }

      .item-info:hover .item-tooltip {
        visibility: visible;
      }
    }

    .vat-text{
      font-size:14px;
      font-weight:400;
    }

    @media(max-width:768px){
    }

    .custom-dot-list{
      li{
        z-index:3;
      }
    }

    .carousel-container{
      padding-bottom:20px;

    }
    .react-multiple-carousel__arrow{
      z-index:10!important;
    }

    

    .add-button {
        background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
        color:white;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:10px;
        cursor:pointer;
        font-size:16px;
      }
  }

  .selected{
    border: 2px solid #99CCCC;
  }



@media only screen and (max-width:360px){
  .add-long-text{
    display:none;
  }
}


@media only screen and (max-width:530px){
  .premade-pack{
    padding:5px;
    margin:10px 16px;
    min-width:calc(100vw - 100px);
    width:calc(100vw - 100px);

    .divider{
      margin:0 auto;
      max-width:100%;
    }

  }
}

@media only screen and (max-width:600px){
  .premade-pack{
      .card-footer{
        .name{
          font-size:20px;
        }
        .price{
          font-size: 20px;
        }
      }
  }
}

.planning {
  position:relative;

  background-color:rgb(246 246 248);

  .planning-content{
    padding:6rem 6rem 3rem 6rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title{
      margin-bottom:10px;
      
    }

    .subtitle{
      max-width:768px;
      margin-bottom:10px;
      text-align: center;
    }

  }
}


@media only screen and (max-width:640px){
  .planning{
    .planning-content{
      padding:10vw;

      .title{
      }
      .subtitle{
        padding:0;
        margin-bottom:25px;
      }
    }
  }
}
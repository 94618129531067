


.orders{

  .title{
    text-align: left;
    color:#323366;
    font-weight: bold;
    font-size:22px;
    margin: 10px 0;
  }
  .order-container{
    display:flex;
    background-color:white;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    margin-bottom : 20px;
    flex-wrap:wrap;
    border-radius:10px;

    .status-container{
      flex:6;
      display:flex;
      justify-content: flex-start;
      align-items: center;

      div{
        flex:3;
        display:flex;
        align-items: center;
        justify-content: center;
        color:white;
        height:100%;
      }

      div .text{
        padding:10px;
        font-weight:bold;
      }

      .created-at{
        border-radius:10px 0 0 10px;
        background-color:#323366;
        color:white;
        width:fit-content;
        min-width:120px;
        height:100%;
        padding:0;
        .date{
          font-weight:bold;
        }
      }

      .order-status{
        border-radius: 0 10px 10px 0;

        
      }

      .delivery-status{
        .text{
          color:#323366;
        }

      }

      .payment-status{
        .text{
          color:#323366;
        }
      }

      .blue{
        background-color:#323366;
      }

      .green{
        background-color:green;
      }

      .red{
        background-color:red;
      }

    }

    .button-container{
      display:flex;
      justify-content: flex-end;
      align-items: center;
      flex:6;
    }

    .tick-icon{
      width:22px;
      height:22px;
      margin-left:5px;
      vertical-align: middle;
      margin-top:-6px;
    }

    .exclamation-icon{
      width:30px;
      height:30px;
      vertical-align: middle;
      margin-top:-6px;
    }

    button{
      float:right;
      background-color:#323366;
      border: 1px solid #323366;
      padding:10px;
      border:none;
      border-radius:20px;
      margin:10px;
      font-size:14px;
      color:white;
      text-decoration: None;
      cursor:pointer;
    }

    .hollow{
      border: 1px solid #323366;
      background-color:white;
      color: #323366;
    }

  }

  .cart-items{
    padding:5px 1vw;
    display:flex;
    flex-direction: column;

    .cart-card{

      background-color:white;
      margin-bottom : 20px;

      .header-container{
        .header{
          font-size:25px;
          text-align: left;
          margin:0;
        }
      }

      .party-details {
        text-align: left;
        margin:4px;
        .party-detail {
          flex:3;


        }
      }

      .party-packs-text{
        text-align: left;
        margin:4px;
      }

      .add-party-pack-button{
        float:right;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:20px;
        font-size:14px;
        color:white;
        text-decoration: None;
        cursor:pointer;
      }

      .delete-party-button{
        float:right;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:20px;
        font-size:14px;
        color:white;
        cursor:pointer;
      }


      .pack{
        .pack-details{
          display:flex;
          flex-direction:row;
          margin:5px;
          .pack-amount{
            flex:4;
            line-height:27px;
            margin-right:20px;
            display:flex;
            .plus-icon, .minus-icon{
              height:19px;
              vertical-align: middle;
              cursor:pointer;
            }
          }

          .pack-name{
            flex:4;
            line-height: 27px;
            margin-right:20px;
          }

          .delete-button{
            flex:4;
            cursor:pointer;
            height:25px;
          }



          .collapsible-button{
            margin-right:20px;
            text-align: left;
            .dropdown-image{
              overflow: hidden;
              transition-duration: 0.2s;
              transition-property: transform;
              width:27px;
              text-align: left;
              cursor:pointer;
            }

            .expanded{
              transform:rotate(-90deg);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:1000px){
  .orders{
    .order-container{
      flex-direction: column;
      .status-container{
        align-items: flex-start;
        justify-content: center;
        width:100%;
        height:50px;

        div{
          height:100%;
        }

        .created-at{
          height:40px;
          .date{
            padding:0;
          }
        }
      }

      .button-container{
        width:100%;
        display:flex;
        justify-content: center;

        button{
          border:1px solid #323366;
          margin:5px;
          flex:4;
        }
      }
    }
  }
}

@media only screen and (max-width:512px){
  .orders{
    .order-container{
      .status-container{
        font-size:15px;
        width: auto;
        flex-direction: column;

        div{
          width:100%;
          border-radius: 0;
        }

        .text{
          width:100%;
          padding:0;
          text-align: center;
        }
        div{
        }

        .created-at{
          width:100%;
          border-radius: 15px 15px 0 0;
          .date{
            padding:5px;
          }
        }

        .order-status{
          border-radius: 0;
          .text{
            padding:5px;
          }
        }

        .delivery-status{
          .text{
            padding:5px;
          }
        }

        .payment-status{
          .text{
            padding:5px;
          }
        }
      }

      .button-container{

        button{
        }
      }
    }
  }
}
.integer-select{
  flex:6;
  display:flex;
  align-items: center;
  justify-content: flex-end;
  float:right;
  font-size:32px;
  position: relative;

  .grey-container{
    position:relative;
    height:35px;
    border-radius:8px;
    background-color: white;
    border: 1px solid #CDCDCD;
    width:120px;
    padding:8px;
    div{
      margin: 0 2px;
    }

    .plus-icon, .minus-icon, .check-icon{
      width:18px;
      padding:3px;
      vertical-align: middle;
      cursor:pointer;
      position:absolute;
      top:12px;
    }

    .plus-icon{
      left:105px;
    }

    .check-icon{
      right:40px;
    }

    .minus-icon{
      left:5px;
      top:20px;
    }

    .input-container{
      display:flex;
      justify-content: center;
      align-items: center;
      .quantity-input{
        width: 50px;
        height:35px;
        font-size: 20px;
        font-weight: bold;
        color: #282F41;
        background-color: white;
        text-align: center;
        border: 0px;
        border-radius:5px;
        padding:0;
      }
    }



    .plus-icon:hover, .minus-icon:hover, .check-icon:hover{
    }
  }
}

.premade-integer-select{
  flex:inherit;

  .grey-container{
    border-radius: 25px 0 0 25px;
    height:32px;

    .plus-icon{
      left:99px;
      top:12px;
    }

    .minus-icon{
      left:12px;
      top:20px;
    }

    .input-container{
      .quantity-input{
        height:30px;
      }
    }

  }
}


@media only screen and (max-width:360px){
  .integer-select{
    .premade-integer-select{

    }
  }
}
.price-filter{
    position:relative;
    .filter-heading{
      background-color:white;
      border: 2px solid rgb(153 204 204);
      left:0px;
      bottom:-60px;
      color:#686D7A;
      padding:17px 23px;
      border-radius:30px;
      font-weight:bold;
      letter-spacing: 1px;
      cursor:pointer;
      font-size:16px;
      white-space: nowrap;
      width:fit-content;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */

      img{
        filter: invert(46%) sepia(4%) saturate(1399%) hue-rotate(186deg) brightness(90%) contrast(85%);
        padding-left:10px;
      }

      .filter-number{

        width:20px;
        height:20px;
        border-radius:50%;
        border: 1px solid white;
        background-color:#99CC66;
        display:flex;
        justify-content: center;
        align-items: center;
        color:white;
        position:absolute;
        top:0px;
        right:0;

        .text{
          font-size:10px;
          font-weight:800;
        }
      }

    }

    .filter-heading:hover{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .active{
        border: 2px solid #99CC66!important;
        color:#99CC66;
        img{
          filter: invert(78%) sepia(19%) saturate(966%) hue-rotate(46deg) brightness(95%) contrast(83%);
        }
    }
  
    .filter-content{
      top:72px;
      background-color: white;
      padding:30px;
      width:400px;
      border-radius:10px;
      border: 1px solid #CDCDCD;
      position: absolute;
      z-index:100;
    }
}




.price-range{
    width:400px;
    .range-container{
      margin: 0 auto;
      width:380px;
    }
    .range-text{
      margin-top:3px;
    }

    .inputs{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
        margin-top: 20px;
        .input-container{
            position:relative;
            .input-label{
                position:absolute;
                font-size:12.8px;
                left:0px;
                top:-15px;
            }
            input{
                border-radius:15px;
                border:1px solid #CDCDCD;
                padding:25px 10px 10px 10px;
                font-size:18px;
                width:120px;
            }
            .value{
              width:120px;
            }
        }

        .input-container-max{
          .input-label{
            right:0px;
            left:63px;
        }
          .value{
            width:120px;
            text-align: right;
          }
        }

        .dash{
            img{
                width:25px;
            }
        }

    }
  }


@media only screen and (max-width:1250px){
  .price-filter{
    margin: 10px 15px;
  }
}



  @media only screen and (max-width:500px){
    .price-range{
      width:auto;
      min-width:250px;

      .range-container{
        margin: 0 auto;
        width:300px;
      }


      .inputs{
        .input-container{
            .input-label{

            }
            input{
              width:90px;
            }
        }

        .dash{
            img{
                width:20px;
            }
        }

    }

    }
  }
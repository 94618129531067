.faq{
    max-width:1920px;
    color:#323366;
    display:flex;
    justify-content: center;
    background-color:#f5f5f5;
    min-height:100vh;
    display:flex;
    flex-direction: column;

    .heading-container{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:80px;
        background-color:#f5f5f5;
        padding-bottom:50px;

        .heading{
            font-style: normal;
            font-weight: 700;
            font-size: 61.04px;
            line-height: 110%;
            margin-bottom:30px;
            text-align: center;
        }

        .sub-heading{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
        }
    }
    .container{
        border-radius:15px;
        padding:80px;
        padding-top:0px;
        height:fit-content;

        .heading{
            font-weight:bold;
            font-size:45px;
            text-align: left;
        }

        .text{
            padding-top:10px;
            font-size: 19px;
            text-align: left;
            display:flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

@media only screen and (max-width:640px){
    .faq{
        .container{
            padding:5vw;
        }
    }
}
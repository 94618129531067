.footer{
  background-color:rgb(246 246 248);
  color:rgb(40 47 65);
  padding-top:1rem;
  padding-bottom:1rem;
  width:100%;
  margin:0 auto;

  .container {
    display:flex;
    margin:50px 15vw 0 15vw;
    max-width:1600px;

    .links{
      flex:6;
      .columns {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap:wrap;
  
        .column:last-child {
          border:none;
        }
  
        .column{
          flex:4;
          margin:0 1vw 1vw 1vw;
          max-width:183px;
          text-align: left;
  
          .heading {
            text-transform:uppercase;
            font-weight:bold;
            font-size:17px;
            white-space:nowrap;
          }
  
          .list{
            list-style: none;
            padding:0;
            line-height:2;
            font-size:14px;
            .link{
              text-decoration: none;
            }
          }
  
          .list-inline {
            li {
              display:inline;
            }
            .payment-icon{
              margin-left:10px;
              margin-right:10px;
            }
          }

          .social-list-inline{
            display:flex;
            li{
              .social-icon{
                width: 30px;
              }
              margin:5px;
            }
          }
  
        }
      }
    }
    

    .email {
      flex:6;
      display:flex;
      flex-direction: column;
      align-items: center;
      text-align: left;

      .sign-up-button{
        padding:20px 30px;
        border-radius:30px;
        color:white;
        text-align: center;
        font-weight:bold;
        width:fit-content;
        border:2px solid #339966;
        color:#339966;
        text-decoration: none;
      }

      .heading {
       text-transform: uppercase;
       font-weight:700;
       padding:0 5px 5px 5px;
       font-size:40px;
     }
     .text {
       font-size:20px;
       padding:5px;
     }
     .inputs {
       display:flex;
       flex-wrap:wrap;
       input:focus {
         outline:none;
       }
  
       input {
        padding:3px 15px;
        margin-right:5px;
        border:none;
        background-color: rgba(0,0,0,0);
        border: 1px solid #dcddde;
        border-radius:10px;
        height:50px;
        font-size:17px;
        margin:20px 0;
      }
  
      .name {
        flex:2;
      }
  
      .email{
        flex:3;
      }
  
      .message{
        flex:5;
      }
  
      .submit{
        flex:2;
        text-transform:uppercase;
        background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
        float:right;
        width:fit-content;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:20px;
        font-size:14px;
        display:inline-block;
        height:auto;
      }
     }
    }

  }

  .banner:first-child{
    margin-top:50px;
  }

  .banner{
    width:100%;
    display:flex;
    flex-direction: column;
    max-width: 85%;
    margin:0 auto;
    border-top:1px solid rgb(201, 203, 212);
    padding-top:35px;

    .socials{
      display:none;
      flex-direction: row;
    }

    .payment-options{
      display:none;
    }

    .trade-mark-container{
      display:flex;
      .trade-mark{
        display:flex;
        justify-content: flex-start;
      }
  
      .rights{
        display:flex;
        justify-content: flex-end;
      }
    }

    div{
      flex:6;
      color:rgb(138, 141, 150);
      font-size:15px;
      padding:10px;
    }


  }

  
}

@media only screen and (max-width: 1400px) {
  .footer{
    .container{
      margin:50px 10vw 0 10vw;
    }
  }
}

@media only screen and (max-width: 1120px) {
  .footer{
    .container{
      margin:50px 5vw 50px 5vw;
    }
  }
}

@media only screen and (max-width: 640px) {
  .footer {
    .container{
      flex-direction: column;
      margin:10vw;
      .links{
        .social-columns{
          display:none;
        }
        .columns{
          .column{
            min-width:150px;
          }
        }
      }
      .email {
        margin-bottom:20px;
        .inputs {
          width:100%;
        }
        align-items:flex-start;

      }
    }


    .banner{
      padding-top:25px;
      max-width: 80%;
      .socials{
        display:flex;
        justify-content: center;
        align-items: center ;
        .social-icon{
          width:40px;
          padding:8px;
        }
        padding-bottom:20px;
      }

      .payment-options{
        display:flex;
        flex-direction: column;
        padding-bottom:30px;

        .title{
          font-weight:bold;
          font-size:20px;
        }

        .payment-icon{
          width:45px;
          padding:8px;
        }
      }

      .trade-mark-container{
        flex-direction: column;
        padding:10px;
        div{
          padding:10px;
        }
        .rights{
          justify-content: flex-start;
        }
      }
    }


  }
}

@media only screen and (max-width:394px){
  .footer{
    .container{
      .links{
        .columns{
          justify-content: center;

          .column{
            text-align: center;
          }

        }
      }

    }
  }
}

.signup{
  .container{
    min-height:calc(100vh - 80px);
    margin-left:7vw;
    margin-right:7vw;
    color:#282F41;

    @media(max-width:990px){
      margin-left: 5vw;
      margin-right: 5vw;
      padding:0;

      .form-section{
        margin-bottom:20px;
      }
    }

    @media(max-width: 500px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    padding:0px 20px 20px 20px;
    margin-bottom:20px;
    .title-block{
      display:flex;
      justify-content: center;
      align-items:center;
      flex-direction: column;
      .title{
        font-size:40px;
        padding-bottom:10px;
        font-weight:bold;
        text-align: center;
      }

      .description{
        font-size: 18px;
        a{
          font-weight:bold;
        }
        margin-bottom:10px;
      }

      .status{
        display:flex;
        .step{
          display:flex;
          justify-content: center;
          align-items: center;
          margin:0 80px;
          .text{
            display:flex;
            justify-content: center;
            align-items: center;
            margin:15px;
            font-size:20px;
          }
          .number{
            display:flex;
            justify-content: center;
            align-items: center;
            width:40px;
            height:40px;
            min-width:40px;
            min-height:40px;
            border-radius:25px;
            background-color:#C5C5C5;
            color:white;
          }
        }

        .active{
          .text{
            font-weight:bold;
          }

          .number{
            background-color:#99CC66;
          }
        }

        .arrow{
          display:flex;
          justify-content: center;
          align-items: center;
          img{
            height:30px;
            width:30px;
            transform: rotate(270deg);
          }
        }
      }

      .existing-account{
        font-size:22px;
        margin-bottom:10px;
        font-weight:bold;
      }

      .error-container{
        .error-message{
          width:100%;
          word-wrap:break-word;
          color:#cc0000;
        }
      }

      .close-button-container{
        width:100%;
        margin-top:10px;

        .close-button{
          float:right;
          background-color:#ff00c4;
          padding:1rem 1.5rem 1rem 1.5rem;
          border:none;
          opacity:.7;
          border-radius:.25rem;
          color:white;
        }
      }
    }
  }

  .signup-form{
    padding:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .same-address-checkbox{
      margin-left:20px;
      display:flex;
      justify-content: flex-start;
      align-items: flex-start;
      input{
        flex:0.2;
      }
      label{
        flex:11.8;
      }
    }

    .form-input{
      display:inline-flex;
      flex-direction:column;
      flex-wrap:wrap;
      justify-content: center;

      .form-section{
        margin-left:5px;
        margin-right:5px;
        width:670px;
        border-radius:15px;
        display:flex;
        justify-content: center;
        align-items:center;


        .column{
          flex:6;
          .form-control{
            margin:10px 10px 1px 10px;
            padding:5px;
            flex:6;

    
            .checkbox{
              width:20px;
              height:20px;
            }
          }
        }
      }


      .select{
        display:flex;
        border-radius:5px;
        border:0 solid #dedede;
        border-width: 1px;
        padding:12px;
        width:100%;
        background-color:white;
      }


    }

    .address-heading{
      text-align:left;
      font-weight: bold;
      padding:15px;
      border-radius:15px 15px 0 0;
      font-size:25px;
      text-align:center;
    }

    .button-container{
      margin-top:20px;
      .next-button{
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        background-color:transparent;
        cursor:pointer;
      }
    }

    .form-submit {
      display:flex;
      justify-content: center;
      align-items: center;
      margin-top:10px;

      .back-button{
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        background-color:transparent;
        cursor:pointer;
        margin:0 15px;
      }
      input{
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        background-color:transparent;
        cursor:pointer;
        margin:0 15px;
      }
    }

    .loader{
      width:80px;
      margin:0 auto;
    }
  }
}


@media only screen and (max-width:768px){
  .signup{
    .signup-form{
      .form-input{
        .form-section{
          width:auto;
          flex-wrap:wrap;
          .column{
            min-width:300px;
            .form-control{
  
            }
          }
        }
      }
    }

    .title-block{
      .status{
        .step{
          margin:0;
          .text{
            margin:0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:490px){
  .signup{
    .container{
      .title-block{
        .status{
          display:none;
          .step{
            margin:0;
            .text{
              margin:0;
            }
            .number{
              display:none;
            }
          }
        }
      }
    }
  }
}

@import '/src/globals.scss';

// @font-face {
//   font-family: 'Quicksand';
//   src: local('Quicksand'), url(./assets/fonts/Quicksand-VariableFont_wght.ttf) format('woff');
// }

// @font-face {
//   font-family: 'Outfit';
//   src: local('Outfit'), url(./assets/fonts/Outfit-VariableFont_wght.ttf) format('woff2');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Avenir';
//   src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Roman.otf) format('woff');
//   font-weight: normal;
//   font-style: normal;
// }


.App {
  position: relative;
  font-family:Arial, Helvetica, sans-serif;
}

.dev-reset{
  position:absolute;
  z-index:1000;
  left:10px;
  top:32px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ANIMATIONS */
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 400ms ease-in-out;
  /* position:absolute; */
}
.slide-exit {
  transform: translateX(0%);
  -webkit-backface-visibility: hidden;
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 400ms ease-in-out;
  -webkit-backface-visibility: hidden;
}

.page {
  min-height: calc(100vh - 80px);
  margin-top:80px;
  position: absolute;
  width:100%;
  -webkit-backface-visibility: hidden;
  background-color:#f5f5f5;
  overflow-x:hidden;
}

.page-container{
  overflow-x:hidden;

}

.notification-container{
  margin-top:80px;

  .notification-error{
    background-color:#E10033;
    .notification-message{
      .title{
        color:white;
      }
    }
  }

  .notification-success{
    background-color:#339966;
  }

  .notification-info{
    background-color:#336699;
  }

  .notification-warning{
    background-color:#FF9966;
  }
}

.sort-by-filter{
    position:relative;
    .filter-heading{
      background-color:white;
      border: 2px solid rgb(153 204 204);
      left:0px;
      bottom:-60px;
      color:#686D7A;
      padding:17px 23px;
      border-radius:30px;
      font-weight:bold;
      letter-spacing: 1px;
      cursor:pointer;
      font-size:16px;
      white-space: nowrap;
      width:fit-content;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */

      img{
        filter: invert(46%) sepia(4%) saturate(1399%) hue-rotate(186deg) brightness(90%) contrast(85%);
        padding-left:10px;
      }

      .filter-number{
        width:20px;
        height:20px;
        font-size:10px;
        border-radius:50%;
        border: 1px solid white;
        background-color:#336699;
        display:flex;
        justify-content: center;
        align-items: center;
        color:white;
        position:absolute;
        top:0px;
        right:0;
        }

    }

    .filter-heading:hover{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }


    .active{
        border: 2px solid #336699!important;
        color:#336699;
        img{
          filter: invert(38%) sepia(18%) saturate(1650%) hue-rotate(169deg) brightness(90%) contrast(89%);
        }
      }
  
    .sort-by-content{
      top:72px;
      background-color: white;
      padding:30px;
      width:400px;
      border-radius:10px;
      border: 1px solid #CDCDCD;
      position: absolute;
      z-index:100;


    }

    
}

.sort-by{
  .clickables{
    .preferred-gender{
      .filter-list{
        list-style: none;
        padding-left:0;
        li{
          display:flex;
          align-items: center;
          input{
            width:25px;
            height:25px;
            margin-top: 5px;
            margin-bottom:5px;
            cursor:pointer;
          }
          input[type='radio']:after {
            display:flex;
            border-radius: 15px;
            background-color: #white;
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: 3px;
            left: 3px;
            position: relative;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
            box-shadow: 0 0 0 2px #339966;
            
          }
    
          input[type='radio']:checked:after {
              display:flex;
              border-radius: 15px;
              background-color: #339966;
              visibility: visible;
              border: 2px solid white;
              content: '';
              display: inline-block;
              width: 15px;
              height: 15px;
              border-radius: 15px;
              top: 3px;
              left: 3px;
              position: relative;
              background-color: #339966;
              content: '';
              box-shadow: 0 0 0 2px #339966;
          }
          label{
            padding-left:15px;
          }
        }

      }
    }
  }
}


@media only screen and (max-width:1250px){
  .gender-filter{
    margin: 10px 15px;
  }
}
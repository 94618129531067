.input-component{
  position:relative;
  label{
    width:60px;
    top:10px;
    left:30px;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    pointer-events: none;
    width:fit-content;
    .text{
      position:absolute;
      top:10px;
      left:23px;
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;
      -ms-transition: all 200ms;
      -o-transition: all 200ms;
      transition: all 200ms;
      z-index:100;
      font-size:14px;
    }

    .label-underline{
      width:calc(100% + 10px);
      display:flex;
      position:absolute;
      top:11px;
      z-index:-1;
      left:0;
      right:0;
      background-color:white;
      height:2px;
      div {
        flex-grow: 1;
        width: 0;
      }
    }

  }
  input{
    background-color: white;
    width: fit-content;
    text-decoration: none;
    display: flex;
    border-radius: 5px;
    border: 0 solid #dedede;
    border-width: 1px;
    padding: 12px;
    width: calc(100% - 24px);
  }

  input:focus ~ label .text, input:not([value='']) ~ label .text{
    top:-10px;
    transform:translateX(-10px);
    font-size:12px!important;
    z-index:100;
  }

  input:focus ~ label .label-underline, input:not([value='']) ~ label .label-underline{
    top:10px;
    font-size:12px!important;
    z-index:-1;
    left:-5px;
  }

}
.button {
  color: white;
  background-color: #323366;
  text-decoration: none;
  padding: 20px 15px;
  border-radius: 30px;
  width:calc(100% - 34px);
  margin:5px;
  cursor:pointer;
  text-align: center;
  font-weight:bold;
  font-size:17px;
  margin:0 auto;
}

.invert{
  border: 1px solid #323366;
  background-color: white;
  color:#323366;
  text-decoration: none;
}
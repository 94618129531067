.password-reset{
  .container{
    min-height:calc(100vh - 80px);
    margin-left:10vw;
    margin-right:10vw;
    padding:20px;
    margin-bottom:20px;
  }

  .password-reset-form{
    padding:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width:fit-content;
    margin:0 auto;

    .form-input{
      max-width: 400px;
      width:400px;
    }
    .form-submit {
      input{
        cursor:pointer;
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: -moz-fit-content;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        background-color:transparent;
        text-decoration: none;
        margin:20px 0;
      }
    }
  }
}

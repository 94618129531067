.password-reset-id{
  .container{
    min-height:calc(100vh - 80px);
    margin-left:10vw;
    margin-right:10vw;
    padding:20px;
    margin-bottom:20px;
    .password-reset-form{
      padding:10px;
      display:flex;
      flex-direction: column;
      width:100%;
      margin:0 auto;
      justify-content: center;
      align-items: center;
      max-width:400px;
      .form-inputs{
        display:flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        width:100%;
        .input-wrapper{
          margin:10px 0;
          width:100%;
        }
      }
  
      .form-submit {
        display:flex;
        justify-content: flex-end;
        width:100%;
        input{
          cursor:pointer;
          padding: 20px 30px;
          border-radius: 30px;
          color: white;
          text-align: center;
          font-weight: bold;
          width: -moz-fit-content;
          width: fit-content;
          border: 2px solid #339966;
          color: #339966;
          text-decoration: none;
        }
      }
  
      .loader{
        width:80px;
        margin:0 auto;
      }
    }
  }



}

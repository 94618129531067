.checkout{
  background-color:hsl(0, 0%, 96%);
  color: #323366;

  .loading-container{
    height:100vh;
    width:50vw;
    background-color: rgba(0,0,0,0.4);
    position:absolute;
    display:flex;
    justify-content: center;
    align-items: center;

    .loading{
        margin:0 auto;
        width:60px;

    }
  }

  .heading{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom:10px;
    .title{
      font-size:45px;
      text-align: left;
    }
    .description{
      text-align: left;
      font-size:20px;
    }
  }

  .step{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    margin:20px 0;
    .step-number{
      background-color:#323366;
      height:80px;
      width:80px;
      border-radius:40px;
      color:white;
      line-height: 80px;
      font-size:45px;
      font-weight:bold;
    }

    .step-title{
      margin-left:20px;
      font-size:40px;
    }
  }

  .cart-summary-container, .pay-container{
    margin-top:20px;
    padding:2vw;
    background-color:white;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    border-radius:10px;

    .heading{
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom:10px;
      .title{
        font-size:30px;
        font-weight:bold;
        text-align: left;
      }
      .description{
        text-align: left;
      }
    }
  }


  
}

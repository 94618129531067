.faq-collapsible{
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    border-radius: 8px;
    margin:20px;
    max-width:900px;
    width:100%;

    .question{
        padding:20px 30px;
        border-radius: 5px;
        color:white;
        background-color:white;
        cursor:pointer;
        font-size:25px;
        font-weight:bold;
        color:#323366;
    }

    .answer{
        border-radius: 0 0 5px 5px;
        padding:20px;
    }

}
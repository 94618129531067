.full-page-header{
    .heading-container{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:80px;
        background-color:#f5f5f5;
        padding-bottom:50px;

        .heading{
            color:#282f41;
            font-style: normal;
            font-weight: 700;
            font-size: 61.04px;
            line-height: 110%;
            margin-bottom:30px;
            text-align: center;
        }

        .sub-heading{
            color:#282f41;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
        }
    }
}

@media only screen and (max-width:640px){
    .full-page-header{
        .heading-container{
            padding-left:50px;
            padding-right:50px;
        }
    }
}

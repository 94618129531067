.pick-pack-header{
    text-align: center;
    padding:30px;
    h2{
      margin-top:15px;
      margin-bottom:15px;
    }
    p{
      text-align: center;
      max-width:620px;
      margin:0 auto;
      margin-bottom:10px;
    }
}

.image-modal{
  position:absolute;
  z-index:151;
  width:100%;
  height:100%;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  justify-content: center;
  align-items: center;

  .underlay{
    position:fixed;
    top:0;
    width:100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.33);
  }

  .main{
    z-index: 11;
    position: fixed;
    top:50%;
    border-radius:15px;
    background-color:#fff;
    width:50%;
    height:auto;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    max-height:calc(100vh - 10px);

    .close-container{
      position: absolute;
      z-index:12;
      right:0;
      text-align: right;
      padding:5px;
      cursor:pointer;
      .close-image{
        width:35px;
        height:35px;
      }

    }

    .image-container{
      display:flex;
      position: relative;
      justify-content: center;
      .item-image{
        width:95%;
        height:auto;
        max-width:600px;
      }
    }

    .modal-footer{
      display:flex;
      flex-direction: column;
      padding:20px;
      justify-content: center;
      align-items: center;
      border-radius:0 0 5px 5px;
      color:black;
  

      .text-box{
        text-align: left;
        display:flex;
        flex-direction: column;
        .description{
          margin:0;
        }
        .price{
          font-weight:bold;
          font-size:20px;
        }

        .vat-text{
          font-weight: 100;
        }
      }

      .button-container{
        .add-to-pack-button{
          background-color:rgb(153 204 204);
          left:calc(50% - 80px);
          bottom:-60px;
          color:white;
          padding:8px 20px;
          border-radius:30px;
          cursor:pointer;
          font-size:18px;
          white-space: nowrap;
          width:fit-content;
          margin:15px auto 0 auto;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
        }

        .added-to-pack-button{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid rgb(153, 204, 204);
          background-color: #336699;
          left: 0px;
          bottom: -60px;
          color: white;
          padding: 6px 18px;
          border-radius: 30px;
          cursor: pointer;
          font-size: 18px;
          white-space: nowrap;
          width: -moz-fit-content;
          width: fit-content;
          margin: 15px auto 0 auto;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          user-select: none;
          .tick{
            width:15px;
          }
        }

        .add-to-pack-button:hover, .added-to-pack-button:hover{
          box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width:1500px){
  .image-modal{
    .main{
      .modal-footer{
        .name{
          font-size:20px;
        }
        .price{
          font-size:22px;
        }
      }
    }
  }
}


@media only screen and (max-width:1240px){
  .image-modal{
    .main{
      .modal-footer{
        .name{
          font-size:16px;
        }
        .price{
          font-size:18px;
        }
      }
    }
  }
}

@media only screen and (max-width:1000px){
  .image-modal{
    .main{
      left:50%;
      top:50%;
      transform: translate(-50%, -50%);
      width:90%;
      .image-container{
        .item-image{
          max-height: calc(70vh - 90px);
          width:auto;
          max-width:95%;
        }
      }
    }
  }
}

@media only screen and (max-width:600px){
  .image-modal{
    .main{
      .image-container{
        .item-image{
          max-width:80vw;
          height:auto;
        }
      }
    }
  }
}

.personal{
  display:flex;
  flex-direction:column;

  .heading-container{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px #EBEBF2 solid;
    margin-bottom:8px;
    .title{
      flex:6;
      text-align: left;
      color:#282F41;
      margin: 10px 0;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }

    .edit-link-container{
      flex:6;
      max-width:300px;
      text-align: right;

      .edit-link{
        a{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color:#686D7A;
          text-decoration: underline;
        }
      }
      
    }
  }



  .field-section{
    display:flex;
    flex-wrap:wrap;
    text-align: left;
    border-radius:10px;
    margin-bottom:15px;
    justify-content: space-between;

    .form-control{
      width:calc(50% - 20px);
      display:flex;
      padding:10px;
      
      .label{
        font-weight: bold;
        min-width:155px;
        line-height: 28px;
      }

      .value{
        line-height:28px;
      }

      .input-component{
        width:100%;
      }

      select{
        display: flex;
        border-radius: 5px;
        border: 0 solid #dedede;
        border-width: 1px;
        padding: 12px;
        width: 100%;
        background-color: white;
      }
    }
  }

  .password-field-section{
    flex-direction: column;
  }
  .submit-button{
    padding: 12px 30px;
    margin-left:15px;
    border-radius: 30px;
    color: white;
    text-align: center;
    font-weight: bold;
    width: fit-content;
    border: 2px solid #339966;
    color: #339966;
    text-decoration: none;
    cursor:pointer;
  }
}

@media only screen and (max-width:768px){
  .personal{
    .field-section{
      .form-control{
        width:100%;
        padding-left:0;
        padding-right:0;
      }
    }
    .password-field-section{
      flex-direction: row;
    }
  }
  }


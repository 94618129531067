

.button-submit{
  cursor: pointer;
  padding: 20px 30px;
  border-radius: 30px;
  text-align: center;
  font-weight: bold;
  width: fit-content;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid rgb(51, 153, 102);
  color: rgb(51, 153, 102);
  font-size:16px;
  margin-left:20px;
}

.button-submit:hover{
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


@media only screen and (max-width:640px){
  .button-submit{
      margin:0 auto;
    }
}
.login-page{
  max-width:1920px;
  margin: 0 auto;
  color:#282F41;
  min-height: calc(100vh - 80px);
  .login-container{
    display:flex;
    flex-direction: column;
    align-items: center;

    .title-block{
      max-width:400px;
      text-align: center;
      margin-bottom:20px;
      .title{
        padding-bottom:10px;
        font-weight: 700;
        font-size: 61.04px;
        color:#282F41;
        line-height: 110%;
      }

      .description{
        margin-bottom:10px;
      }

      .close-button-container{
        width:100%;
        margin-top:10px;

        .close-button{
          float:right;
          background-color:#ff00c4;
          padding:1rem 1.5rem 1rem 1.5rem;
          border:none;
          border-radius:.25rem;
          color:white;
        }
      }
    }
  }

  .signup-form{
    padding:10px;
    display:flex;
    flex-direction: column;
    max-width:640px;
    min-width:400px;
    margin: 0 auto;
    border-radius:10px;

    .forgot-password{
      display:flex;
      justify-content: center;
      padding-bottom:30px;
    }

    .form-input{
      display:flex;
      flex-direction:column;
      width:100%;
      margin:0 auto;

      .form-control{
        padding:10px;
      }
    }


    .button-container {
      display:flex;
      justify-content: center;

      input, a{
        padding: 20px 70px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: fit-content;
        border: 2px solid #339966;
        color: #339966;
        text-decoration: none;
        cursor:pointer;
        background-color:transparent;
        font-size:16px;
      }



      a{
        background-color:white;
        color:#323366;
      }
    }

    .loader{
      width:80px;
      margin:0 auto;
    }
  }

  .forgot-or-register{
    display:flex;
    flex-direction:column;
    a{
      text-decoration: none;
      padding:5px;
    }
  }

  .forgot-password{
    font-weight:bold;
  }

  .register{
    a{
      font-weight:bold;
    }
  }

}

@media only screen and (max-width:1000px){

  .login-page{
    .login-container{
      .title-block{
        .title{
          font-size:41.8px;
        }
      }
    }
  }

}

@media only screen and (max-width:468px){

  .login-page{
    .signup-form{
      min-width:90vw;
    }
  }

}

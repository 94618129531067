.get-started{
  min-height:calc(100vh - 5rem);
  width:100%;
  z-index:0;
  display:flex;
  position:relative;

  .background-image{
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    background-size:cover;
    object-fit:cover;
    z-index:-2;
  }

  .title-block{
    text-align:center;
    z-index:1;
    padding:27vh 15vw;
    text-align: left;
    color:#282f41;

    .title{
      font-size:55px;
      font-weight:bold;
      margin-bottom:10px;
    }

    .subtitle{
      font-size: 20px;
      font-weight:500;
      margin-bottom:15px;
    }
  }
}

@media only screen and (max-width:640px){
  .get-started{
    .title-block{
      padding-top:100px;
      .title{
        margin-top:0;
        text-align:center;
      }
      .subtitle{
        text-align:center;
      }
    }
  }
}

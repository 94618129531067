
.cart-payment {
  .background-image{
    position: absolute;
    height:100%;
    left:0;
    background-size:cover;
    object-fit:cover;
    z-index:-2;
    opacity:.5;
    max-width: 100%;
  }

  .heading {
    padding:40px;
    padding-bottom:15px;

    .title{
      font-size:40px;
      color:#212121;
    }

    .description{
      font-size:28px;
      color:black;

      .swap-icon-text{
        width:20px;
      }
    }
  }

  .container{
    display:flex;
    justify-content: center;
    margin-left:0;
    margin-right:0;

    .cart-container{
      display:flex;

      .cart-items{
        width:60vw;
        padding:1vw;
        display:flex;
        flex-direction: column;

        .cart-card{

          .party-pack-container{
            background-color: white;
            padding:25px;
            border-radius:12px;
            margin-bottom: 30px;
          }

          margin-bottom : 20px;

          .header-container{
            .header{
              text-align: left;
              margin:0;
            }
          }

          .party-details {
            text-align: left;
            margin:4px;
            .party-detail {
              flex:3;


            }
          }

          .party-packs-text{
            text-align: left;
            margin:4px;
          }


          .delete-party-button{
            background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
            float:right;
            padding:15px;
            border:none;
            border-radius:5px;
            margin:20px;
            font-size:14px;
            color:white;
            cursor:pointer;
          }

          .party-supplies-banner{
            background-color:black;
            box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
            margin-bottom:20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            height:100px;
            position:relative;

            .image{
              position:absolute;
              opacity:0.7;
              flex-shrink: 0;
              width: 100%;
            }

            .content{
              display:flex;
              z-index:10;
              justify-content:center;
              align-items: center;
              width:100%;
              .text{
                flex:9;
                font-size:25px;
                font-weight:bold;
                text-align: left;
                color:white;
                width:100%;
                padding-left:15px;
              }
              .button-container{
                flex:3;
                .button{
                  background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
                  padding:15px;
                  border:none;
                  min-width:100px;
                  border-radius:5px;
                  font-size:16px;
                  color:white;
                  font-weight:bold;
                }
              }
            }

          }
        }
      }
      
    }

    .empty-cart{
      height:calc(100vh - 300px);
      margin:25px;
      .text{
        font-size:26px;
        text-align: center;
        margin:20px;
      }
      .button-container{
        margin-top:50px;
        .create-link{
          background-color:#323366;
          padding:15px;
          border:none;
          border-radius:5px;
          margin:20px;
          font-size:20px;
          color:white;
          text-decoration: none;
        }
      }
    }
  }
}

.transition-container{
  overflow:hidden;
  height:100%;
  display:flex;
  background-color:#f5f5f5;
}

@media only screen and (max-width: 800px) {
  .cart{
    .container{
      .cart-container{
        flex-direction:column;
        .cart-items{
          width:auto;
          .card-container{
            justify-content: center;
          }

        }
        .cart-summary-container{
          width:auto;
        }
      }
    }

  }
}


@media only screen and (max-width: 1010px) {
  .cart{
    .container{
      .cart-container{
        .cart-summary-container{
          .checkout-form{
            justify-content: center;
            align-items: center;
            .date-field{
              .label{
                text-align: center;
              }
              .datepicker{
                margin:0 auto;
              }
            }

            .submit-button-container{
              margin:10px;
              .submit-button{
                float:none;
                padding:15px 25px;
              }
            }
          }

          .add-party-pack-button{
            float:none;
            margin:10px auto;
          }
        }
      }
    }
  }
}


/* SLIDE PACK */

.slide-pack-enter {
  transform: translateX(-100%);
}
.slide-pack-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;

}
.slide-pack-exit {
  transform: translateX(0%);

}
.slide-pack-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;

}

/* SLIDE CATALOGUE */

.slide-cat-enter {
  transform: translateX(100%);

}
.slide-cat-enter-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;

}
.slide-cat-exit {
  transform: translateX(-100%);

}
.slide-cat-exit-active {
  transform: translateX(100%);
  transition: transform 500ms ease-in-out;

}

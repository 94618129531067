

.create-preview{
  /* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99cccc;
  border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  .container{
    max-width:308px;
    min-height:304px;
    max-height:80vh;
    overflow-y:scroll;
    justify-content: center;
    border: 1px solid transparent;
    position:relative;
    margin:15px;;
    background-color:white;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    border-radius:10px;
    padding-left:5px;
    height:fit-content;
    padding:30px;
    border: 2px solid #99CCCC;
  }

  .preview-heading{
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #99CCCC;
    text-align: center;
    .title{
      text-align: left;
      margin:0;
      padding-bottom:15px;
    }
    .sub-title{
      font-size:20px;
    }

    .dropdown-container{
      display:none;
      .dropdown-icon{
        width:20px;
        cursor:pointer;
        transform: rotate(180deg);
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: 0.25s;
      }
      .drop-collapsed{
        transform: rotate(0deg);
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: 0.25s;
      }
    }


  }

  .item{
    display:flex;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid #99CCCC;
    padding:18px 0;

    .close-container{
      flex:1;
      height:100%;
      display:flex;
      align-items: center;
      cursor:pointer;
      .close-icon{
        height:20px;
        width:20px;
        margin-left:15px;
      }
    }

    .image-container{
      flex:2;
      .item-image{
        height:60px;
        width:60px;
      }
    }

    .description{
      flex:7;
      font-size:16px;
      text-align: left;
    }

    .price{
      flex:2;
      margin:0;
    }
  }

  .item:last-child {
    border-bottom: none;
  }

  .mobile-item-amount{
    display:none;
  }

  .items{
    .title{
      text-align: left;
      color: #323366;
      font-weight:600;
    }


    .pack-amount-container{
      display:flex;
      justify-content: center;
      align-items: center;
      padding:25px 0;
      border-bottom:1px solid #99CCCC;

      .label{
        flex:6;
        text-align: left;
      }
      .pack-amount{
        flex:6;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        float:right;
        font-size:32px;
        position: relative;

        .grey-container{
          position:relative;
          height:35px;
          border-radius:5px;
          background-color: #f6f6f8;
          width:120px;
          padding:8px;
          div{
            margin: 0 2px;
          }
    
          .plus-icon, .minus-icon, .check-icon{
            width:18px;
            padding:3px;
            vertical-align: middle;
            cursor:pointer;
            position:absolute;
            top:10px;
          }

          .plus-icon{
            left:105px;
          }

          .check-icon{
            right:40px;
          }

          .minus-icon{
            left:5px;
            top:20px;
          }

          .input-container{
            display:flex;
            justify-content: center;
            align-items: center;
            .quantity-input{
              width: 50px;
              height:35px;
              font-size: 25px;
              font-weight: bold;
              color: #282F41;
              background-color: #f6f6f8;
              text-align: center;
              border: 0px;
              border-radius:5px;
              padding:0;
            }
          }
        }

  

      }
    }

    .price-per-pack, .total-price-pack{
      padding-top:20px;
      display:flex;
      .label{
        flex:6;
        text-align: left;
        font-weight: bold;
      }

      .price{
        flex:6;
        text-align: right;
        margin:0;
      }
    }
    



    .no-items{
      text-align: center;
      padding: 10px 0;
      max-width:200px;
      margin: 30px auto 5px auto;
    }

    .no-items-small-text{
      font-size:16px;
      line-height:140%;
      letter-spacing: -2%;
      text-align:center;
      max-width:250px;
      margin:0 auto;
    }
  }

  .edit-small-text{
    font-size:12.8px;
    line-height:140%;
    letter-spacing: -2%;
    text-align:left;
    max-width:350px;
    margin:20px auto 0 auto;
  }

  .button-container{
    margin-top:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .total{
      font-weight:bold;
      font-size:24px;
      color:#323366;
      text-align: left;
    }
    .submit-button{
      background-color:rgb(153 204 204);
      color:white;
      padding:10px 27px;
      border-radius:30px;
      cursor:pointer;
      font-size:22px;
    }

    .submit-button:hover{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }


  }

}

.disclaimer-tiny-text{
  font-size:12px;
  line-height:140%;
  letter-spacing: -2%;
  text-align:left;
  max-width:350px;
  margin:20px auto 0 auto;
  font-style:italic;
}


@media only screen and (max-width:1500px){
  .create-preview{
    max-width:280px;
    .preview-heading{
      .title{
        font-size:22px;
      }
      .sub-title{
        font-size:16px;
      }
    }
  }
}


@media only screen and (max-width:1000px){
  .create-preview{
    width:auto;
    margin-top:15px;
    max-width:100%;
    .heading{
      .title{
        font-size:17px;
      }
    }
  }
}




@media only screen and (max-width:768px){
  .create-preview{
    position:fixed;
    top:calc(100% - 100px);
    width:100%;
    margin:0;
    padding:0;
    max-height: calc(100vh - 80px);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: 0.25s;
    border-radius:25px;
    left:0;

    .preview-heading{
      border:0;
      .title{
        flex:10;
        font-size:20px;
        padding:0;
      }
      .dropdown-container{
        display:block;
      }
    }

    .items{
      overflow-y:scroll;
      max-height: calc(100vh - 310px);
      .pack-amount-container{
        padding:10px 0;
      }
    }

    .item{
      padding:8px 0;
    }

    .mobile-item-amount{
      display:flex;
      font-size:12.8px;
      color:#686D7A;
    }

    .container{
      max-height: calc(100vh - 200px);
      min-height: calc(100vh - 200px);
      padding:12px 25px 25px 25px;
      overflow:scroll;
      margin:0;
      max-width:100%;
      
    }
  }
  
  .expanded{
    max-height:100vh;
    height:100vh;
    transform: translateY(calc(0px - 60vh));
    transition: 0.25s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

    .container{
      max-height:100vh;
      height:100vh;
    }
  }


}
.returns-and-refunds{
    max-width:1920px;
    color:#323366;
    display:flex;
    justify-content: center;
    background-color:#f5f5f5;
    min-height:100vh;
    flex-direction: column;

    h4{
        margin-bottom:5px;
    }
    .container{
        background-color:white;
        padding:100px 15vw 100px 15vw;
        height:fit-content;

        .heading{
            font-weight:bold;
            font-size:45px;
            text-align: left;
        }

        .text{
            padding-top:10px;
            font-size: 19px;
            text-align: left;
        }
    }
}

@media only screen and (max-width: 1000px){
    .returns-and-refunds{
        .container{
            padding: 30px 6.5vw;
        }
    }
}


.page-not-found{
  padding-top:80px;
  .container{
    min-height:calc(100vh - 84px);
    display:flex;
    justify-content: center;

    .button-container{
      margin-top:150px;
      .button{
        flex: 6 1;
        padding: 20px 30px;
        border-radius: 30px;
        color: white;
        text-align: center;
        font-weight: bold;
        width: -moz-fit-content;
        width: fit-content;
        background-color:rgb(224, 132, 163);
      }
    }
  }
}


.cart-info {

  background-color: #f6f6f8;


  .heading {
    padding:40px;
    padding-bottom:15px;

    .title{
      font-size:40px;
      color:#212121;
    }

    .description{
      font-size:28px;
      color:black;

      .swap-icon-text{
        width:20px;
      }
    }
  }
}


.individual-pack{
    background-color:#f5f5f5;
    min-height:calc(100vh - 80px);
    display:flex;
    align-items:center;
    // justify-content: center;
    margin: 0 auto;
    flex-direction: column;

    .loader-container{
      padding:200px;
    }

    .card{
      // display:flex;
      // align-items:center;
      // justify-content: center;
        margin:0 auto;
        margin-top: 50px;
        // flex:4;
        margin-bottom:15px;
        height:fit-content;
        .carousel-wrapper{
          display:flex;
          justify-content: center;
          border: 1px solid transparent;
          position:relative;
          margin:15px 15px 8px 15px;
          background-color:white;
          box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
          border-radius:10px;
          padding-left:5px;
          height:fit-content;
          padding:30px 70px 30px 70px;
          width:fit-content;
          // padding:100px;
  
          .confetti-animation{
            width:1000px;
          }
  
          .item-image-container{
            position:relative;
            text-align: left;
            .item-image{
              margin: auto;
              width:100%;
              // height:200px;
              padding-bottom:10px;
            }

            .item-image:hover{
              cursor: -moz-zoom-in; 
              cursor: -webkit-zoom-in; 
              cursor: zoom-in;
            }
  
            .magnifying-glass{
              width:30px;
              height:30px;
              transition: .5s ease;
              opacity: 0;
              position: absolute;
              top: 20px;
              left: 20px;
              -ms-transform: translate(-50%, -50%);
              text-align: center;
            }
            
          }
  
          .item-image:hover {
            opacity: 0.8;
          }
  
          .select-container{
            display:flex;
            justify-content: center;
            align-items: center;
            right:-15px;
            top:-15px;
            margin-right:0;
            z-index:10;
            border: 1px solid #323366;
            background-color:#fff;
            color:white;
            width:30px;
            height:30px;
            border-radius:25px;
            font-size:30px;
            z-index:10;
  
            .tick, .plus{
              width:18px;
              height:18px;
            }
  
          }
  
          .vat-text{
            font-size:14px;
            font-weight:400;
          }
  
          @media(max-width:768px){
            flex:12;
          }
  
          .custom-dot-list{
            li{
              z-index:3;
            }
          }
  
          .carousel-container{
            padding-bottom:20px;
          }
  
          .item-info{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height:70px;
            background-color:white;

            position:relative;
            .divider{
              border-top:1px solid #99CCCC;
              width:100%;
              top:-6px;
              padding-bottom:15px;
            }
  
            .item-image-container{
              flex:2;
              text-align: left;
              .item-image{
                width:70px;
                height:70px;
                border-radius:5px;
              }
            }
  
  
            .text-box{
              flex:10;
              text-align: left;
              font-size:16px;
              color:#323366;
            }
  
  
  
            .item-tooltip{
              visibility: hidden;
              width: 200px;
              background-color: white;
              text-align: center;
              border-radius: 6px;
              padding: 5px 0;
              margin-left:150px;
              margin-top:-40px;
              position: absolute;
              z-index: 1;
            }
  
            .item-info:hover .item-tooltip {
              visibility: visible;
            }
          }
  
          .add-to-pack-button{
            background-color:rgb(153 204 204);
            left:calc(50% - 80px);
            bottom:-60px;
            color:white;
            padding:8px 20px;
            border-radius:30px;
            cursor:pointer;
            font-size:18px;
            white-space: nowrap;
            width:fit-content;
            margin:15px auto 0 auto;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
            display:flex;
            height:34px;
            align-items:center;
            .add-short-text{
              display:none;
            }
            .plus-container{
              position:relative;
              width:18px;
              .plus{
                position:absolute;
                font-size:28px;
                top:-19px;
              }
            }
          }

          .added-to-pack-button{
            display:flex;
            align-items:center;
            justify-content: center;
            border: 2px solid rgb(153 204 204);
            background-color: #336699;
            left:0px;
            bottom:-60px;
            color:white;
            padding:6px 18px;
            border-radius:30px;
            cursor:pointer;
            font-size:18px;
            white-space: nowrap;
            width:fit-content;
            margin:15px auto 0 auto;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
            display:flex;
            height:34px;
            align-items:center;
            .remove-short-text{
              display:none;
            }
            .tick-container{
              position:relative;
              width:20px;
              .tick{
                top:-10px;
                position:absolute;
                width:20px;
              }
            }
          }

          .add-to-pack-button:hover, .added-to-pack-button:hover{
            box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
          }
        }
  
  
        .item-info{
          display:flex;
          border-radius:5px;
          
  
          .text-box{
            text-align: left;
            display:flex;
            flex-direction: column;
            .description{
              margin:0;
              min-height:46px;
            }
            .price{
              font-weight:bold;
              font-size:20px;
            }

            .vat-text{
              font-weight: 100;
            }
          }
        }

        .selected{
          border: 2px solid #99CCCC;
        }
      }

      .button-container{
        .button{
          background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
          padding:15px;
          border:none;
          min-width:100px;
          border-radius:5px;
          font-size:16px;
          color:white;
          font-weight:bold;
        }

        a{
          text-decoration: none;
          .landing-button{
            flex:6;
            padding:20px 30px;
            border-radius:30px;
            color:white;
            text-align: center;
            font-weight:bold;
            width:fit-content;
          }
        }
  
        .build{
          background-color: rgb(153 204 204);
          margin-right:5px;
        }
  
        .pick{
          background-color: rgb(224 132 163);
          margin-left:5px;
        }
      }




}
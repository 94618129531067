

  .build-your-own{
    /* width */
    height:355px;
    border: 1px solid transparent;

    .text{
      margin:0;
      text-align: center;
    }

    .exploding-confetti{
      position:absolute;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height:12px;
      position:absolute;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius:10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #99CCCC;
      border-radius:10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }


    padding:19px 25px;
    padding-top:27px;
    padding-bottom:36px;
    position:relative;
    flex:6;
    max-width:420px;
    margin:0 auto;
    margin-bottom:20px;
    margin-left: 10px;
    margin-right:10px;
    min-width:370px;
    background-color:white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    border-radius:10px;

    .select-container{
      display:flex;
      justify-content: center;
      align-items: center;
      position:absolute;
      right:-15px;
      top:-15px;
      margin-right:0;
      z-index:10;
      border: 2px solid #99CCCC;
      background-color:#fff;
      color:white;
      width:40px;
      height:40px;
      border-radius:25px;
      font-size:40px;

      .tick, .plus{
        width:25px;
        height:25px;
      }

    }

    .out-of-stock{
      position:absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom:40px;
      padding:0 10px 0 10px;
      height:40px;
      background-color:#db4d4d;
      z-index:10;
      display:flex;
      justify-content: center;
      align-items: center;
      .text{
        flex: 7;
        font-size: 25px;
        text-align: left;
        color:white;
      }
      .sad-face{
        width:25px;
        height:25px;
        margin-left:10px;
      }
    }



    .image-container{
      display:flex;
      justify-content: center;
      align-items:center;
      height:100%;
      img{
        height:300px;
        width:300px;
      }
      .build-button{
        position:absolute;
        left:50px;
        bottom:58px;
        border:1px solid #DD6E0F;
        background-color:#DD6E0F;
        color: white;
        padding: 8px 20px;
        border-radius: 30px;
        cursor: pointer;
        font-size: 18px;
        white-space: nowrap;
        width: fit-content;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height:34px;
        display:flex;
        justify-content: center;
        align-items: center;
      }

      .build-button:hover{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }

    .card-footer{
      display:flex;
      justify-content: center;
      align-items: center;
      border-radius:0 0 5px 5px;
      height:40px;
      color:white;
      padding:0 10px 0 10px;

      .name{
        text-align: center;
        font-size:25px;
        text-align: left;
      }

      .price{
        text-align: center;
        font-size:28px;
        font-weight:bold;
        margin:0 auto;
      }
    }

    .item-info{
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height:70px;
      background-color:white;



      .item-image-container{
        position:relative;
        width:70px;
        height:70px;
        flex:2;
        text-align: left;
        .item-image{
          width:70px;
          height:70px;
        }

        .magnifying-glass{
          width:30px;
          height:30px;
          transition: .5s ease;
          opacity: 0;
          position: absolute;
          top: 20px;
          left: 20px;
          -ms-transform: translate(-50%, -50%);
          text-align: center;
        }
        
      }

      .item-image-container:hover .item-image {
        opacity: 0.8;
      }
      
      .item-image-container:hover .magnifying-glass {
        opacity: 0.9;
      }


      .text-box{
        flex:8;
        text-align: left;
      }



      .item-tooltip{
        visibility: hidden;
        width: 200px;
        background-color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        margin-left:150px;
        margin-top:-40px;
        position: absolute;
        z-index: 1;
      }

      .item-info:hover .item-tooltip {
        visibility: visible;
      }
    }

    .footer-item-info{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position:relative;
      .divider{
        border-top:1px solid #99CCCC;
        width:460px;
      }

      .item-image-container{
        flex:2;
        text-align: left;
        .item-image{
          width:70px;
          height:70px;
          border-radius:5px;
        }
      }


      .text-box{
        height:70px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        font-size:16px;
        padding-top:0px;

        .build-button:hover{
          background-color: #82a8a8;
        }


      }

      .button-container{
        .add-to-pack-button{
          background-color:rgb(153 204 204);
          left:calc(50% - 80px);
          bottom:-60px;
          color:white;
          padding:8px 20px;
          border-radius:30px;
          cursor:pointer;
          font-size:18px;
          white-space: nowrap;
          width:fit-content;
          margin:15px auto 0 auto;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
        }

        .added-to-pack-button{
          border: 2px solid rgb(153 204 204);
          left:0px;
          bottom:-60px;
          color:rgb(153 204 204);
          padding:6px 18px;
          border-radius:30px;
          cursor:pointer;
          font-size:18px;
          white-space: nowrap;
          width:fit-content;
          margin:15px auto 0 auto;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
          .tick{
            width:15px;
          }
        }

        .add-to-pack-button:hover, .added-to-pack-button:hover{
          box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        }
      }



      .item-tooltip{
        visibility: hidden;
        width: 200px;
        background-color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        margin-left:150px;
        margin-top:-40px;
        position: absolute;
        z-index: 1;
      }

      .item-info:hover .item-tooltip {
        visibility: visible;
      }
    }

    .vat-text{
      font-size:14px;
      font-weight:400;
    }

    .custom-dot-list{
      li{
        z-index:3;
      }
    }

    .carousel-container{
      padding-bottom:20px;
    }

    

    .add-button {
        background-image:linear-gradient(270deg,#ff00c4,#7f21cd);
        color:white;
        padding:15px;
        border:none;
        border-radius:5px;
        margin:10px;
        cursor:pointer;
        font-size:16px;
      }
  }

  .selected{
    border: 2px solid #99CCCC;
  }






@media only screen and (max-width:1000px){
  .build-your-own{

  }
}

@media only screen and (max-width:530px){
  .build-your-own{
    margin:10px;
    padding:5px;
    min-width: calc(100vw - 100px);
    width:calc(100vw - 100px);
    .image-container{
      img{
        position: absolute;
        top:80px;
        width:240px;
        height:auto;
      }
    }
  }
}

@media only screen and (max-width:600px){
  .build-your-own{
      .card-footer{
        .name{
          font-size:20px;
        }
        .price{
          font-size: 20px;
        }
      }
  }
}

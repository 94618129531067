.item-notification-modal{
  position:absolute;
  z-index:11;
  width:100%;
  height:100%;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  justify-content: center;
  align-items: center;


  .underlay{
    position:absolute;
    width:100vw;
    height:100%;
    background-color: rgba(0,0,0,0.33);
  }

  .main{
    z-index: 11;
    position: fixed;
    top:25%;
    border-radius:15px;
    background-color:#fff;
    width:600px;
    height:auto;

    .close-container{
      text-align: right;
      padding:5px;
      cursor:pointer;
      .close-image{
        width:35px;
        height:35px;
      }

    }

    .checkbox-container{
      display:flex;
      justify-content: center;
      .tag-element-container{
        display:flex;
        align-items: center;
  
        .text-label{
          padding-left:15px;
          color:#484F63;
        }
  
        .checkbox-container{
          padding:2px;
  
  
  
          input[type="checkbox"] { /* change "blue" browser chrome to yellow */
            color: #323366;
            visibility: hidden;
          }
    
          .checkbox {
            width: 24px;
            height: 24px;
            border:1px solid #686D7A;
            border-radius:5px;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
            margin: 5px auto;
            position: relative;
          }
    
          .checkbox label {
            cursor: pointer;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0px;
            top: 0px;
          }
    
          .checkbox label:after {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            opacity: 0;
            content: '';
            position: absolute;
            width: 26px;
            height: 26px;
            background: #339966;
            border-radius:5px;
            left:-1px;
            top:-1px;
          }
    
          .checkbox label:hover::after {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
            filter: alpha(opacity=30);
            opacity: 0.3;
          }
    
          .checkbox input[type=checkbox]:checked + label:after {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            opacity: 1;
          }
        }
  
        label{
          font-size:20px;
        }
      }
    }

    .button-container{
      padding:20px 0;
      .add-to-pack-button{
        background-color:rgb(153 204 204);
        left:calc(50% - 80px);
        bottom:-60px;
        color:white;
        padding:8px 20px;
        border-radius:30px;
        cursor:pointer;
        font-size:18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        white-space: nowrap;
        width:fit-content;
        margin:15px auto 0 auto;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
      }

      .added-to-pack-button{
        border: 2px solid rgb(153 204 204);
        left:0px;
        bottom:-60px;
        color:rgb(153 204 204);
        padding:6px 18px;
        border-radius:30px;
        cursor:pointer;
        font-size:18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        white-space: nowrap;
        width:fit-content;
        margin:15px auto 0 auto;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
        .tick{
          width:15px;
        }
      }
    }

    .heading{
      font-size:39.06px;
      font-weight:bold;
      color:#282F41;
    }

    .text-box{
      padding:30px;
      text-align: center;
      flex-direction: column;
      color:#484F63;
      font-size:20px;
      font-weight:400;
      .description{
        margin:0;
      }
      .price{
        font-weight:bold;
        font-size:20px;
      }

      .vat-text{
        font-weight: 100;
      }
    }

    .image-container{
      display:flex;
      position: relative;
      justify-content: center;
      .item-image{
        width:300px;
        height:300px;
      }
    }

    .modal-footer{
      display:flex;
      flex-direction: column;
      padding:20px;
      justify-content: center;
      align-items: center;
      border-radius:0 0 5px 5px;
      color:black;
  




    }
  }
}

@media only screen and (max-width:1500px){
  .item-notification-modal{
    .main{

      .text-box{

      }

      .modal-footer{
        .name{
          font-size:20px;
        }
        .price{
          font-size:22px;
        }
      }
    }
  }
}


@media only screen and (max-width:1240px){
  .item-notification-modal{
    .main{

      .text-box{

      }
      .modal-footer{
        .name{
          font-size:16px;
        }
        .price{
          font-size:18px;
        }
      }
    }
  }
}

@media only screen and (max-width:742px){
  .item-notification-modal{
    .main{
      width:400px;

    }
  }
}

@media only screen and (max-width:442px){
  .item-notification-modal{
    .main{
      width:calc(100vw - 30px);

    }
  }
}
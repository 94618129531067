.land{
  min-height:calc(100vh - 5rem);
  z-index:0;
  display:flex;
  position:relative;
  overflow: hidden;

  .background-container{
    .landing-background-image{
      position: absolute;
      top: 0;
      left:0;
      width:100%;
      background-size:cover;
      object-fit:cover;
      z-index:-2;
      transform: scale(1.2) translateX(100px) translateY(50px);
    }
  }

  .title-block{
    text-align:center;
    z-index:1;
    padding:27vh 15vw;
    text-align: left;
    color:#282f41;

    .subtitle{
      font-size: 20px;
      font-weight:500;
      margin:20px 0;
      color:#282f41;
    }
  }
}

@media only screen and (max-width:1200px){
  .land{
    .background-container{
      .landing-background-image{
        transform: scale(1.7) translateX(120px) translateY(72px);
      }
    }

  }
}

@media only screen and (max-width:640px){
  .land{
    background-color:transparent;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height:auto;
    height: calc(100vh - 80px);
    .background-container{
      height:40vh;
      overflow-x: hidden;
      .landing-background-image{
        position:relative;
        transform: scale(2.2) translateX(0) translateY(0);
      }
    }

    .title-block{
      padding:0;
      margin:30px 0 50px 0;
      text-align: center;
      padding: 0 30px;
    }


  }
}

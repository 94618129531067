.basic-info{
  min-height: calc(100vh - 80px);

  .profile-container{
    margin-left:7vw;
    margin-right:7vw;
    padding:20px;
    margin-bottom:20px;
    .heading{
      text-align: left;
      margin-left:30px;
    }

    .profile-form{
      padding:10px;
      display:flex;
      flex-direction: column;

      .address-heading{
        text-align:left;
        background-color:#323366;
        color:white;
        font-weight: bold;
        padding:15px;
        border-radius:15px 15px 0 0;
        font-size:25px;
        text-align:center;
      }
      .form-input{
        display:inline-flex;
        flex-wrap:wrap;
        flex-direction:row;

        input{
          display:flex;
          border-radius:.25rem;
          border:0 solid #dedede;
          border-width: 1px;
          padding:.75rem;
          padding-right:0px;
          width:calc(100% - 0.95rem);
        }

        .select{
          display:flex;
          border-radius:.25rem;
          border:0 solid #dedede;
          border-width: 1px;
          padding:.75rem;
          width:100%;
          background-color:white;
        }

        .form-section{
          flex:4;
          margin:5px;
          min-width:250px;
          box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
          border-radius:15px;
          padding-bottom:10px;
        }

      }

      .button{
        background-color:#323366;
        padding:0.5rem 0.5rem 0.5rem 0.5rem;
        border:none;
        border-radius:.25rem;
        color:white;
      }

      .form-control{
        margin:0px 10px 0px 10px;
        padding:5px;
        flex:6;

        label{
          display:block;
          text-align:left;
          padding:5px;
          font-size:14px;
          color:#4a5568;
        }

        .email, .password{
          display:flex;
          input{
            flex:8;
          }
          button{
            flex:4;
          }
        }
      }


      .button-container{
        display:flex;
        justify-content: center;
        align-items: center;
        margin: 0px 13px 0px 13px;
        .button{
          flex:6;
          margin:3px;
        }
      }

      .form-submit {
        input {
          float:right;
          background-color:#323366;
          padding:1rem 1.5rem 1rem 1.5rem;
          border:none;
          border-radius:.25rem;
          color:white;
          margin:10px;
          opacity: 1;
        }
      }

      .loader{
        width:80px;
        margin:0 auto;
      }
    }
  }
}

.verify-email{
  .container{
    margin-left:200px;
    margin-right:200px;
    margin-top:50px;
    height:calc(100vh - 130px);

    .title-block{

      .title{
        font-size:40px;
      }

      .close-button-container{
        width:100%;
        margin-top:10px;

        .close-button{
          float:right;
          background-color:#ff00c4;
          padding:1rem 1.5rem 1rem 1.5rem;
          border:none;
          opacity:.7;
          border-radius:.25rem;
          color:white;
        }
      }
    }
  }

  .signup-form{
    padding:10px;
    display:flex;
    flex-direction: column;

    .form-input{
      display:flex;
      flex-direction:column;

      input{
        display:flex;
        border-radius:.25rem;
        border:0 solid #dedede;
        border-width: 1px;
        padding:.75rem;
        width:100%;
      }

      .form-control{
        margin:0px 10px 0px 10px;
        padding:5px;
        flex:6;

        label{
          display:block;
          text-align:left;
          padding:5px;
          font-size:14px;
          color:#4a5568;
        }
      }
    }

    .form-submit {
      input{
        float:right;
        background-color:#ff00c4;
        padding:1rem 1.5rem 1rem 1.5rem;
        border:none;
        opacity:.7;
        border-radius:.25rem;
        color:white;
        margin-top:10px;
      }
    }

    .loader{
      width:80px;
      margin:0 auto;
    }
  }
}



.side-panel{
  .underlay{
    position:fixed;
    top:0;
    width:100vw;
    height:100vh;
    z-index:1001;
    background-color:rgba(0,0,0,0.1)
  }

  .party-pack:last-child{
    .pack-price-container{
      border:none;
    }
  }


  .content{
    position:fixed;
    right:0;
    width:450px;
    height:100vh;
    background-color:#F6F6F8;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    z-index:1002;
    padding:0 0 0 25px;

    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    
    .heading {
      padding-bottom:15px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EBEBF2;
  
      .title{
        text-align: left;
      }
  
      .cross-icon{
        cursor: pointer;
  
        img{
          height:35px;
          width:35px;
        }
      }
    }
  
    .container{
      overflow-y: scroll;
      margin-left:0;
      margin-right:0;
      display:flex;
      flex-direction:column;
      padding:30px;
      max-height: calc(100vh - 140px);

      .side-panel-link{
        padding:10px 0;
        font-style: normal;
        font-size: 29px;
      }

    }
  }

  .slide-in-from-left {
    animation: slide-in-from-left 0.5s forwards;
    -webkit-animation: slide-in-from-left 0.5s forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    animation-delay: 0s;
    animation-timing-function: ease-out;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }

  .slide-out-from-left {
      animation: slide-out-from-left 0.5s forwards;
      -webkit-animation: slide-out-from-left 0.5s forwards;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
  }

  .closed-side-panel {
    animation: slide-out-from-left 0s forwards;
    -webkit-animation: slide-out-from-left 0s forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
      
  @keyframes slide-in-from-left {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
  }

  @-webkit-keyframes slide-in-from-left {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }  }
      
  @keyframes slide-out-from-left {
      0% { transform: translateX(0%); }
      100% { transform: translateX(-100%); }
  }

  @-webkit-keyframes slide-out-from-left {
      0% { -webkit-transform: translateX(0%); }
      100% { -webkit-transform: translateX(-100%); }
  }


}

.transition-container{
  overflow:hidden;
  height:100%;
  display:flex;
  background-color:#f5f5f5;
}


@media only screen and (max-width:840px){

  .side-panel{
    .underlay{
      display:none;
    }
  
    .content{
      z-index:999999;
      height:100vh;
      top:0;
      width:100%;
      padding:0;

      .heading{
        padding:30px;
        .title{
          margin:0;
        }
      }

      .container{
        padding:0 30px;
        .cart-container{
          padding:0 30px;
          .fixed-container{
            background-color:#F6F6F8;
          }
        }
      }
    }
  }

}

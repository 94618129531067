.main{
  display:flex;
  flex-direction:column;
  height:fit-content;
  height:100%;
}

.scroller{
  @media only screen and (max-width:768px){
    display:none;
  }
  width:140px;
  text-align: center;
  padding:20px 30px;
  padding-bottom:0;
  border-radius:30px;
  color:white;
  font-weight:bold;
  position:fixed;
  left:calc(50% - 75px);
  bottom:0;
  z-index:2;
  .scroll-container{
    .text{
      position:absolute;
      bottom:0;
      left:calc(50% - 25px);
      width:50px;
    }
  }


}
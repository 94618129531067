.how-it-works{
  position:relative;
  background-color:white;
  margin:0 auto;
  width:100%;

  .heading{
    max-width:1200px;
    text-align: center;
    margin: 40px auto;
  }

  .steps{
    display:flex;
    flex-direction:column;
    max-width:1200px;
    margin:0 auto;
    .step{
      margin:50px 0;
      display:flex;
      justify-content: center;
      align-items: center;
      position:relative;
      
      .image-container{
        flex:6;
        .grey-container{
          display:flex;
          justify-content: center;
          align-items: center;
          background-color:rgb(246 246 248);
          border-radius:15px;
          width:fit-content;
          padding:100px;
          margin:0 auto;
          height:300px;
          width:300px;

        }

        .image-background{
          position:relative;
          background-color: white;
          border-radius: 10px;
          width:fit-content;
          padding:20px;
          margin:20px auto;
          .add-to-pack-button{
            position:absolute;
            background-color:rgb(153 204 204);
            right:-30px;
            top:-20px;
            color:white;
            padding:15px 30px;
            border-radius:30px;
            font-weight: bold;
            font-size:22px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          }

          .image{
            padding:10px;
          }

          .pointer{
            filter: invert(15%) sepia(7%) saturate(2766%) hue-rotate(188deg) brightness(95%) contrast(89%);
            position:absolute;
            left:150px;
            top:80px;
            transform:rotate(-20deg);
          }
        }


      }

      .text-container{
        flex:6;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .text{
          padding:60px;

          .step-number{
            border-radius:50%;
            height:35px;
            width:35px;
            display:flex;
            justify-content: center;
            align-items:center;
            span{
              font-size:25px;
              font-weight: bold;
              color:white;
            }
          }

          .yellow{
            background-color:rgb(255 205 52);
          }

          .teal{
            background-color:rgb(153 204 204);
          }

          .green{
            background-color:rgb(153 204 102);
          }
        }

      }

      .green-squiggle{
        position:absolute;
        bottom:-80px;
        right:42%;
        width:100px;
      }

      .star-dots{
        position:absolute;
        top:-80px;
        left:-50px;
        width:80px;
      }

      .star-dots-2{
        position:absolute;
        right:0px;
        bottom:0;
        width:80px;
      }

      .yellow-squiggle{
        position:absolute;
        bottom:-80px;
        right:52%;
        width:100px;
      }

    }

    .checkout-step{
      .image-container{
        .grey-container{
          .image-background{
            margin:0 auto;
            .image{
              width:100px;
              max-width:100px;
              padding:50px;
            }
          }
        }
      }
    }

    .get-order-step{
      .image-container{
        .grey-container{
          .image{
          }
        }
      }

    }

    .step-reverse{
      flex-direction: row-reverse;
    }
  }
  
}


@media only screen and (max-width: 1000px){

  .how-it-works{
    .steps{
      .step{
        flex-direction: column-reverse;
      }
    }
  }

}


@media only screen and (max-width: 1360px) and (min-width: 640px){
  .how-it-works{
    .steps{
      .step{
        .image-container{
          .grey-container{
            padding:60px;
            .image-background{
              margin:0;
              padding:0;
              .image{
                width:260px;
              }
              .pointer{
                top:55px;
                left:125px;
              }
            }
          }
        }
        .text-container{
          .text{
            padding:100px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 640px){
  .how-it-works{
    .steps{
      .step{
        .image-container{
          .grey-container{
            padding:40px;
            margin:10px auto;
            .image-background{
              margin:0;
              padding:0;
              .image{
                width:200px;
              }
            }
          }
        }
        .text-container{
          .text{
            padding:40px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 640px){
  .how-it-works{
    width:100vw;
    .steps{
      .step{
        margin-bottom:100px;
        .image-container{
          .grey-container{
            padding:5vw;
            margin:0 16px;
            width: calc(80vw);
          }
          .image-background{
            margin:0 auto;
            .image{
              width:45vw;
            }
            .pointer{
              top:45px;
              left:105px;
            }
          }
        }

        .text-container{
          .text{
            padding:0 5vw;
            .title-container{
              display:flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              h3{
                font-size:32px;
              }


              .step-number{
                margin-right:10px;
              }
            }
            p{
              text-align: left;
              padding-bottom:15px;
            }
          }
        }

        .green-squiggle{
          bottom:-120px;
          right:38%;
        }


        .yellow-squiggle{
          bottom:-100px;
          right:25%;
        }

        .star-dots-2{
          right:25%;
          bottom:-55px;
          width:80px;
          display:none;
        }

        .star-dots{
          display:none;
        }
      }

      .checkout-step{
        .image-container{
          .grey-container{
            margin:50px;
            .image-background{
              padding:20px;
              .image{
                max-width:80px;
                padding:30px;
              }
            }
          }

        }

      }

      .get-order-step{
        img{
          width:80vw;
        }
      }
    }
  }
}
.privacy-policy{
    max-width:1920px;
    color:#323366;
    display:flex;
    justify-content: center;
    background-color:#f5f5f5;
    min-height:100vh;
    flex-direction: column;

    .paia-manual-link{
        text-decoration: underline;
    }
    .heading-container{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top:80px;
        background-color:#f5f5f5;
        padding-bottom:50px;

        .heading{
            font-style: normal;
            font-weight: 700;
            font-size: 61.04px;
            line-height: 110%;
            margin-bottom:30px;
            text-align: center;
        }

        .sub-heading{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
        }
    }
    .container{
        background-color:white;
        padding:100px 15vw 100px 15vw;
        height:fit-content;

        .heading{
            font-weight:bold;
            font-size:45px;
            text-align: left;
        }

        .text{
            padding-top:10px;
            font-size: 19px;
            text-align: left;
        }
    }
}

@media only screen and (max-width: 768px){
    .privacy-policy{
        .container{
            padding:20px 30px 20px 30px;
            margin:0px 5vw 0px 5vw;
            .text{
                p{
                    font-size:15px;
                }
            }
        }
    }
}

.activation-success{
  margin-top:150px;
  padding:10px;

  .title {
   color:black;
   font-size:22px;
  }

  .green-tick{
    width:56px;
    height:56px;
  }

  .login-button{
    background-color:#ff00c4;
    padding:1rem 1.5rem 1rem 1.5rem;
    border:none;
    opacity:.7;
    border-radius:.5rem;
    color:white;
    margin:15px;
  }
}
